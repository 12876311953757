import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import successIcon from "assets/images/success-icon-placeholder.png";
import { Link, Typography } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { RouteEnum } from "model/enums/RouteEnum";
import { IDonePageProps } from "pages/Challenge/challenge";
import BackButton from "../../../components/BackButton";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  logo: {
    width: "50%",
    display: "block",
    margin: "auto",
  },
  text: {
    textAlign: "center",
  },
  successText: {
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
    fontWeight: "bold",
    fontSize: theme.typography.fontSize,
  },
}));

const Done: React.FC<IDonePageProps> = React.memo(({ header, text }) => {
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    const timeout = setTimeout(() => history.push(RouteEnum.CHALLENGE), 20000);
    return () => clearTimeout(timeout);
  }, [history]);

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={successIcon} alt="todo" />
      <Typography className={`${classes.successText} ${classes.text}`}>
        {header}
      </Typography>
      <Typography className={classes.text} variant="body1">
        {text}
      </Typography>
      <Link
        className={classes.text}
        variant="body1"
        component={RouterLink}
        to={RouteEnum.CHALLENGE}
      >
        <Typography variant="subtitle1">
          <BackButton />
        </Typography>
      </Link>
    </div>
  );
});

export default Done;
