import React from "react";
import { IInitiativeDetailActionsProps } from "pages/Initiatives/initiative";
import { InitiativeStatusEnum } from "pages/Initiatives/Detail/InitiativeStatusEnum";
import ActionButtons from "pages/Initiatives/Detail/ActionButtons";
import ActionsDialog from "pages/Initiatives/Detail/ActionsDialog";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "model/enums/RouteEnum";
import FullPageLoader from "components/FullPageLoader";

const Actions: React.FC<IInitiativeDetailActionsProps> = ({ initiative }) => {
  const api = useApi();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [dialogType, setDialogType] = React.useState<
    undefined | InitiativeStatusEnum
  >();

  const confirmActions = async () => {
    setIsLoading(true);
    try {
      await api.put(`${ApiUrlEnum.INITIATIVES}/${initiative._id}`, {
        status: dialogType,
      });
      history.push(RouteEnum.INITIATIVES);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <ActionsDialog
        dialogType={dialogType}
        close={() => setDialogType(undefined)}
        confirm={confirmActions}
      />
      <ActionButtons setDialogType={setDialogType} />
    </>
  );
};

export default Actions;
