import React, { BaseSyntheticEvent, FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useApi } from "model/hooks/useApi";
import { Alert } from "@material-ui/lab";

const Imports: FC = memo(() => {
  const api = useApi();
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [files, setFiles] = useState([] as any);
  const [isLoading, setIsLoading] = useState({
    users: false,
    squads: false,
    statements: false,
    questions: false,
  });

  const handleSetFile = (e: BaseSyntheticEvent, kind: string) => {
    setFiles({
      ...files,
      [kind]: e.target?.files[0],
    });
  };

  const handleImport = async (kind: string, path = "import") => {
    setIsLoading({
      ...isLoading,
      [kind]: true,
    });

    if (files[kind]) {
      const formData = new FormData();
      formData.append("file", files[kind], kind);

      try {
        const response = await api.post(`/${kind}/${path}`, formData);
        console.log(response);
      } catch (ex) {
        setError(`Objevila se chyba: ${ex.message}`);
        console.log(ex);
        setIsLoading({
          ...isLoading,
          [kind]: false,
        });
      } finally {
        setSuccess(true);
        setFiles([]);
        setIsLoading({
          ...isLoading,
          [kind]: false,
        });
      }
    }

    setTimeout(
      () =>
        setIsLoading({
          ...isLoading,
          [kind]: false,
        }),
      500
    );
  };

  return (
    <Grid container spacing={3}>
      {success && (
        <Grid item xs={12}>
          <Alert severity="success">Import byl úspěšně proveden...</Alert>
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom component="h5">
              {t("admin.buttons.imports")}
            </Typography>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="h6">
                    {t("admin.imports.questions")}
                  </Typography>
                  <input
                    accept="text/csv"
                    type="file"
                    onChange={(e: BaseSyntheticEvent) =>
                      handleSetFile(e, "questions")
                    }
                  />
                  <Button
                    disabled={!files.questions}
                    component="span"
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleImport("questions")}
                  >
                    {isLoading.questions
                      ? "Importing..."
                      : t("admin.imports.buttons.upload")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="h6">
                    {t("admin.imports.statements")}
                  </Typography>
                  <input
                    accept="text/csv"
                    type="file"
                    onChange={(e: BaseSyntheticEvent) =>
                      handleSetFile(e, "statements")
                    }
                  />
                  <Button
                    disabled={!files.statements}
                    component="span"
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleImport("statements")}
                  >
                    {isLoading.statements
                      ? "Importing..."
                      : t("admin.imports.buttons.upload")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="h6">
                    {t("admin.imports.users")}
                  </Typography>
                  <input
                    accept="text/csv"
                    type="file"
                    onChange={(e: BaseSyntheticEvent) =>
                      handleSetFile(e, "users")
                    }
                  />
                  <Button
                    disabled={!files.users}
                    component="span"
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleImport("users", "import-override")}
                  >
                    {isLoading.users
                      ? "Importing..."
                      : t("admin.imports.buttons.upload")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="h6">
                    {t("admin.imports.squads")}
                  </Typography>
                  <input
                    accept="text/csv"
                    type="file"
                    onChange={(e: BaseSyntheticEvent) =>
                      handleSetFile(e, "squads")
                    }
                  />
                  <Button
                    disabled={!files.squads}
                    component="span"
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleImport("squads", "import-override")}
                  >
                    {isLoading.squads
                      ? "Importing..."
                      : t("admin.imports.buttons.upload")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default Imports;
