export enum ApiUrlEnum {
  ANSWERS_QUEUE = "answers/queue",
  TRAININGS_QUEUE = "trainings/queue",
  FREE_TRAININGS_QUEUE = "trainings/free-test",
  TRAININGS_MARK_AS_COMPLETED = "trainings/mark-as-completed",
  TRAINING_POST_ANSWER = "trainings/answer",
  TRAINING_POST_FREE_ANSWER = "trainings/free-answer",
  USERS_QUEUE_STATUSES = "users/queues-statuses",
  REVIEWS = "reviews",
  AUTH_USER = "auth/user",
  STATEMENTS_GROUPED = "statements/grouped",
  STATEMENTS_POST_ANSWER = "answers",
  PERSONAL_POINTS = "users/points",
  TEAM_POINTS = "users/team-points",
  INITIATIVES_STATUSES_LIST = "initiatives/statuses",
  INITIATIVES_GROUPED = "initiatives/grouped",
  INITIATIVES = "initiatives",
  INITIATIVES_DETAIL = "initiatives/detail",
  COMMENTS = "comments",
}
