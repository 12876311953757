import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    /* padding: theme.spacing(2), */
  },
  image: {
    maxWidth: "100%",
  },
  title: {
    padding: theme.spacing(2, 0),
  },
  text: {
    color: "#AAAAAA",
  },
  content: {
    textAlign: "center",
    flexGrow: 1,
  },
}));

const Slide: React.FC<{
  item: {
    key: number;
    name: string;
    description: string;
    image: string;
  };
}> = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.item}>
      <img className={classes.image} alt="People" src={item.image} />
      <div className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          {item.name}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {item.description}
        </Typography>
        {item.key >= 3 && (
          <Link
            variant="body1"
            component={RouterLink}
            to="/"
            color="inherit"
            underline="none"
            style={{ display: "inline-block", marginTop: 20 }}
          >
            {t("tutorial.finish")}
            <ArrowForwardIos
              fontSize="inherit"
              style={{ marginLeft: 5, position: "relative", top: 2 }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default Slide;
