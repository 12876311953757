import React, { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useApi } from "model/hooks/useApi";
import { AppBarLayout, ButtonsList, Layout } from "components";
import { useHistory } from "react-router-dom";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { RouteEnum } from "model/enums/RouteEnum";
import Question from "./Question";
import ProgressCircle from "../../../components/Training/ProgressCircle";

const useStyles = makeStyles({
  resultContainer: {
    textAlign: "center",
    paddingBottom: "20px",
  },
});

const Training: FC<{ free?: boolean }> = ({ free }) => {
  const api = useApi();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [training, setTraining] = useState<ITraining>();
  const [canGoNext, setCanGoNext] = useState(false);
  const [triggeredAttempt, setTriggeredAttempt] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [wrongAnswers, setWrongAnswers] = useState<IWrongAnswer[]>([]);
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(1);

  useEffect(() => {
    api
      .get<ITraining>(
        free ? ApiUrlEnum.FREE_TRAININGS_QUEUE : ApiUrlEnum.TRAININGS_QUEUE
      )
      .then((response) => {
        setTraining(response.data);
        setQuestionId(response.data.questions[0]._id);
        setCanGoNext(false);
        setTriggeredAttempt(false);
        setCurrentIndex(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [api, free]);

  const getQuestionByIndex = React.useCallback(
    (index: number) => {
      return training?.questions[index];
    },
    [training]
  );

  const getQuestionById = React.useCallback(
    (id: string) => training?.questions.find((x) => x._id === id),
    [training]
  );

  const isLastQuestion = React.useCallback(
    (index: number) => !getQuestionByIndex(index + 1),
    [getQuestionByIndex]
  );

  const getMistakes = useCallback(() => {
    const uniqueMistakesIds: string[] = [];
    wrongAnswers?.forEach((a: IWrongAnswer) => {
      if (!uniqueMistakesIds.includes(a.questionId)) {
        uniqueMistakesIds.push(a.questionId);
      }
    });

    return uniqueMistakesIds;
  }, [wrongAnswers]);

  const resetAttempt = React.useCallback(() => {
    setTriggeredAttempt(false);
    setCanGoNext(false);
  }, []);

  const goToNextQuestion = React.useCallback(() => {
    resetAttempt();
    setCurrentIndex(currentIndex + 1);
    /* const currentIndex = training?.questions.findIndex(
      (x) => x._id === questionId
    ); */

    // if (typeof currentIndex === "number") {
    const nextQuestionId = getQuestionByIndex(currentIndex)?._id;

    if (nextQuestionId && !isLastQuestion(currentIndex)) {
      setQuestionId(nextQuestionId);
    } else if (!readOnlyMode && !free) {
      api
        .put(`${ApiUrlEnum.TRAININGS_MARK_AS_COMPLETED}/${training?._id}`)
        .then(() => {
          setQuestionId("");
        });
    } else {
      setQuestionId("");
    }
    // }
  }, [
    training,
    getQuestionByIndex,
    isLastQuestion,
    resetAttempt,
    api,
    readOnlyMode,
    free,
    currentIndex,
  ]);

  const setAnswer = React.useCallback(
    (answerId: string) => {
      if (questionId && training) {
        api
          .post<IAnswer>(
            free
              ? ApiUrlEnum.TRAINING_POST_FREE_ANSWER
              : ApiUrlEnum.TRAINING_POST_ANSWER,
            {
              training: training?._id,
              question: questionId,
              answer: answerId,
            }
          )
          .then(({ data }) => {
            setTraining({
              ...training,
              questions: training.questions.map((x) => {
                if (x._id === questionId) {
                  return {
                    ...x,
                    answered: answerId,
                    isCorrect: data.isCorrect,
                  };
                }

                return x;
              }),
            });
            setTriggeredAttempt(true);

            setCanGoNext(data.isCorrect);
            if (data.isCorrect === false) {
              setWrongAnswers([...wrongAnswers, { questionId, answerId }]);
            }
          });
      }
    },
    [questionId, training, api, wrongAnswers, free]
  );

  if (loading) {
    return (
      <AppBarLayout>
        <Layout top={<Typography>{t("challenge.training.header")}</Typography>}>
          <div>Loading</div>
        </Layout>
      </AppBarLayout>
    );
  }

  if (questionId) {
    const question = getQuestionById(questionId);

    return (
      <AppBarLayout>
        <Layout
          top={
            <Typography variant="subtitle1">
              {t("challenge.training.header")}
            </Typography>
          }
        >
          {question && (
            <Question
              type={question.type}
              question={question.question}
              questionId={questionId}
              answers={question.answers}
              setAnswer={setAnswer}
              answered={question.answered}
              isCorrect={question.isCorrect}
              canGoNext={canGoNext}
              triggeredAttempt={triggeredAttempt}
              goToNextQuestion={goToNextQuestion}
              resetAttempt={resetAttempt}
              readOnlyMode={readOnlyMode}
              wrongAnswers={wrongAnswers}
              free={free}
            />
          )}
        </Layout>
      </AppBarLayout>
    );
  }

  return (
    <AppBarLayout>
      <Layout>
        <Paper className={classes.resultContainer}>
          {!!training && (
            <>
              <Typography variant="h6">
                {t("challenge.training.resultsLabel")}
              </Typography>
              <ProgressCircle
                errorsPercentage={
                  (getMistakes().length * 100) / training.questions.length
                }
              />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {t("challenge.training.rightAnswers")}{" "}
                    {training?.questions.length - getMistakes().length}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {t("challenge.training.wrongAnswers")}{" "}
                    {getMistakes().length}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        <ButtonsList
          buttons={[
            {
              key: 1,
              onClick: () => {
                history.push(RouteEnum.CHALLENGE);
              },
              variant: "contained",
              color: "primary",
              text: t("challenge.training.backToHomepage"),
              disabled: false,
            },
            {
              key: 2,
              onClick: () => {
                setReadOnlyMode(true);
                if (training?.questions) {
                  setCurrentIndex(1);
                  setQuestionId(training.questions[0]._id);
                }
              },
              variant: "outlined",
              color: "primary",
              text: t("challenge.training.viewResults"),
              disabled: false,
            },
          ]}
        />
      </Layout>
    </AppBarLayout>
  );
};

export default Training;
