import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IStats } from "../initiative";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
  },
  filledCount: {
    color: theme.palette.success.main,
  },
  unfilledCount: {
    color: theme.palette.error.main,
  },
}));

const Stats: React.FC<IStats> = ({ commented = 0, waiting = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.filledCount}>
          {t("initiatives.detail.stats.waitingForCommentCount")} {commented}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.unfilledCount}>
          {t("initiatives.detail.stats.alreadyCommentedCount")} {waiting}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Stats;
