import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { TrainingCard } from "components/Training/Card";
import { ButtonsList, IButton } from "components";
import { useHistory } from "react-router-dom";
import { Answer, IAnswer } from "./Answer";
import { RouteEnum } from "../../../model/enums/RouteEnum";

const useStyles = makeStyles((theme) => ({
  errorBtn: {
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
    },
  },
  successBtn: {
    background: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.main,
    },
  },
}));

interface IProps {
  type: string;
  question?: string;
  questionId: string;
  answers: IAnswer;
  setAnswer: (answerId: string) => void;
  answered?: string;
  isCorrect?: boolean;
  canGoNext?: boolean;
  triggeredAttempt?: boolean;
  resetAttempt: () => void;
  goToNextQuestion: () => void;
  readOnlyMode: boolean;
  wrongAnswers: IWrongAnswer[];
  free?: boolean;
}

const Question: React.FC<IProps> = ({
  type,
  question,
  questionId,
  answers,
  setAnswer,
  isCorrect,
  answered,
  canGoNext,
  triggeredAttempt,
  resetAttempt,
  goToNextQuestion,
  readOnlyMode,
  wrongAnswers,
  free,
}) => {
  const [selectedAnswer, setSelectedAnswer] = React.useState<string>();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const buttons: IButton[] = [];
  if (readOnlyMode) {
    buttons.push({
      key: 1,
      onClick: () => {
        goToNextQuestion();
      },
      variant: "contained",
      color: "primary",
      text: t("challenge.training.next"),
      disabled: false,
    });
  } else if (canGoNext) {
    buttons.push({
      key: 1,
      onClick: () => {
        goToNextQuestion();
        setSelectedAnswer("");
      },
      variant: "contained",
      classes: { root: classes.successBtn },
      color: "primary",
      text: t("challenge.training.next"),
      disabled: false,
    });
  } else if (triggeredAttempt) {
    buttons.push({
      key: 2,
      onClick: () => {
        setSelectedAnswer("");
        resetAttempt();
      },
      variant: "contained",
      color: "secondary",
      classes: { root: classes.errorBtn },
      text: t("challenge.training.tryAgain"),
      disabled: false,
    });
  } else {
    buttons.push({
      key: 3,
      onClick: () => {
        if (selectedAnswer) {
          setAnswer(selectedAnswer);
        }
      },
      variant: "contained",
      color: "primary",
      text: t("challenge.training.confirm"),
      disabled: !selectedAnswer,
    });
  }

  if (free) {
    buttons.push({
      key: 4,
      onClick: () => {
        return history.push(RouteEnum.HOMEPAGE);
      },
      variant: "contained",
      color: "default",
      text: t("challenge.training.leave"),
      disabled: false,
    });
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <TrainingCard>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {type.trim() === "Poznejte výrok mezi špatnými"
                ? "Poznejte správný výrok"
                : type}
            </Typography>
          </Grid>
          {type.trim() !== "Poznejte výrok mezi špatnými" && (
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                &quot;{question}&quot;
              </Typography>
            </Grid>
          )}
          <Grid container direction="column" spacing={2}>
            {Object.entries(answers).map(([id, answer]) => (
              <Grid item xs={12} key={id}>
                <Answer
                  key={questionId}
                  id={id}
                  text={answer}
                  isCorrect={!!isCorrect}
                  answered={answered}
                  wrongAnswers={wrongAnswers}
                  questionId={questionId}
                  selected={selectedAnswer === id}
                  onSelect={() => {
                    if (!readOnlyMode) {
                      resetAttempt();
                      setSelectedAnswer(id);
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </TrainingCard>
      </Grid>
      <Grid item>
        <ButtonsList buttons={buttons} />
      </Grid>
    </Grid>
  );
};

export default Question;
