import React, { FC, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { IButton, Button } from "components";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

interface IProps {
  buttons: IButton[];
}

export const ButtonsList: FC<IProps> = memo(({ buttons }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {buttons.map((props) => {
          const { key, ...buttonProps } = props;
          return (
            <Button key={key} className={classes.button} {...buttonProps} />
          );
        })}
      </Grid>
    </Grid>
  );
});
