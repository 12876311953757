import { Icon } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import empathy from "../../../../assets/images/categories/empathy.svg";
import eye from "../../../../assets/images/categories/eye.svg";
import expectations from "../../../../assets/images/categories/expectations.svg";
import integrity from "../../../../assets/images/categories/integrity.svg";
import personalisation from "../../../../assets/images/categories/personalisation.svg";
import resolution from "../../../../assets/images/categories/resolution.svg";
import shape from "../../../../assets/images/categories/shape.svg";
import shape2 from "../../../../assets/images/categories/shape2.svg";
import time from "../../../../assets/images/categories/time.svg";

const useStyles = makeStyles(() => ({
  icon: {
    width: "20px",
    height: "20px",
  },
  iconImg: {
    width: "100%",
  },
}));

const CustomIcon: FC<{ source: string }> = ({ source }) => {
  const classes = useStyles();

  return (
    <Icon className={classes.icon}>
      <img className={classes.iconImg} alt="icon" src={source} />
    </Icon>
  );
};

export const EmpathyIcon = <CustomIcon source={empathy} />;
export const EyeIcon = <CustomIcon source={eye} />;
export const ExpectationsIcon = <CustomIcon source={expectations} />;
export const IntegrityIcon = <CustomIcon source={integrity} />;
export const PersonalisationIcon = <CustomIcon source={personalisation} />;
export const ResolutionIcon = <CustomIcon source={resolution} />;
export const ShapeIcon = <CustomIcon source={shape} />;
export const Shape2Icon = <CustomIcon source={shape2} />;
export const TimeIcon = <CustomIcon source={time} />;
