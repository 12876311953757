import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IResultStatsProps } from "pages/Challenge/challenge";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  answeredCount: {
    color: theme.palette.success.main,
    "& span": {
      fontWeight: "bold",
    },
  },
  unansweredCount: {
    color: theme.palette.error.main,
    "& span": {
      fontWeight: "bold",
    },
  },
}));

const Stats: React.FC<IResultStatsProps> = ({
  results: { unansweredCount, answeredCount },
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.answeredCount}>
          <>
            Vyplnilo: <span>{answeredCount}</span>
          </>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.unansweredCount}>
          <>
            Čeká na vyjádření: <span>{unansweredCount}</span>
          </>
        </Typography>
      </Grid>
    </div>
  );
};

export default Stats;
