import React, { FC, memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Drawer,
  Divider,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";

import classNames from "classnames";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import AttachmentIcon from "@material-ui/icons/Attachment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { RouteEnum } from "../../model/enums/RouteEnum";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto",
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  list: {
    width: "100%",
    maxWidth: 600,
    minWidth: 320,
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: 1.1,
  },
  progress: {},
  button: {
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const AdminLayout: FC = memo(({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(true);

  const handleDrawerOpen = () => {
    setIsDrawerOpened(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpened(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={classNames(
          classes.appBar,
          isDrawerOpened && classes.appBarShift
        )}
      >
        <Toolbar disableGutters={!isDrawerOpened} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(
              classes.menuButton,
              isDrawerOpened && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Admin
          </Typography>
          <IconButton
            color="inherit"
            component={Link}
            to={RouteEnum.ADMIN_SETTINGS}
          >
            <SettingsIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to={RouteEnum.HOMEPAGE}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !isDrawerOpened && classes.drawerPaperClose
          ),
        }}
        open={isDrawerOpened}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button component={Link} to={RouteEnum.ADMIN_DASHBOARD}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.ADMIN_EXPORTS}>
              <ListItemIcon>
                <AttachmentIcon />
              </ListItemIcon>
              <ListItemText primary="Exporty" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.ADMIN_IMPORTS}>
              <ListItemIcon>
                <SystemUpdateAltIcon />
              </ListItemIcon>
              <ListItemText primary="Importy" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.ADMIN_ANALYTICS}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Reporty" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.ADMIN_AUDIT}>
              <ListItemIcon>
                <VerifiedUserIcon />
              </ListItemIcon>
              <ListItemText primary="Audit" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.ADMIN_SETTINGS}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Nastavení" />
            </ListItem>
            <ListItem button component={Link} to={RouteEnum.HOMEPAGE}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </div>
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </main>
    </div>
  );
});
