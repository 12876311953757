import { IBreadcrumb } from "components/AppBar/app-bar";
import { RouteEnum } from "model/enums/RouteEnum";

export const getBreadcrumbs = (pathname: string): IBreadcrumb | null => {
  if (pathname.startsWith(RouteEnum.CHALLENGE)) {
    return {
      label: "menu.breadcrumbs.challenge",
      link: RouteEnum.CHALLENGE,
    };
  }

  if (pathname.startsWith(RouteEnum.STATEMENTS)) {
    return {
      label: "menu.breadcrumbs.statements",
      link: RouteEnum.STATEMENTS,
    };
  }

  if (pathname.startsWith(RouteEnum.INITIATIVES)) {
    return {
      label: "menu.breadcrumbs.initiatives",
      link: RouteEnum.INITIATIVES,
    };
  }

  if (pathname.startsWith(RouteEnum.ACCESSIBILITY)) {
    return {
      label: "menu.accessibility",
      link: RouteEnum.ACCESSIBILITY,
    };
  }

  return null;
};
