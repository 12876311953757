import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface IProgressCardProps {
  title: string;
  description: string;
  image: string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  disabled: {
    filter: `grayscale(1)`,
  },
  textHeader: {
    lineHeight: "1em",
    marginBottom: "5px",
    fontWeight: 600,
  },
  textBody: {
    color: theme.palette.grey[600],
    fontWeight: 450,
    fontSize: "14px",
  },
  textDisabled: {
    color: theme.palette.grey[400],
  },
  imageBox: {
    marginTop: "5px",
  },
}));

const ProgressCard: React.VFC<IProgressCardProps> = ({
  title,
  description,
  image,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      direction="row"
      wrap="nowrap"
      justify="space-between"
      spacing={2}
    >
      <Grid item>
        <Box
          width={76}
          height={76}
          className={`${classes.imageBox} ${disabled ? classes.disabled : ""}`}
        >
          <img src={image} alt={title} width="100%" />
        </Box>
      </Grid>
      <Grid container item direction="column">
        <Grid item>
          <Typography
            variant="h6"
            className={`${classes.textHeader} ${
              disabled ? classes.textDisabled : ""
            }`}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className={`${classes.textBody} ${
              disabled ? classes.textDisabled : ""
            }`}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProgressCard;
