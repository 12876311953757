import React, { FC, memo } from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StatementGroup } from "../statement";

import {
  EmpathyIcon,
  ExpectationsIcon,
  EyeIcon,
  IntegrityIcon,
  PersonalisationIcon,
  ResolutionIcon,
  Shape2Icon,
  ShapeIcon,
  TimeIcon,
} from "./Icons";

const useStyles = makeStyles((theme) => ({
  head: {
    marginTop: theme.spacing(4),
    fontSize: "15px",
  },
  button: {
    justifyContent: "flex-start",
  },
}));

export const StatementsGroup: FC<{
  id: StatementGroup;
  getGroup: any;
  setCategoryGroup: any;
}> = memo(({ id, getGroup, setCategoryGroup }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const group = getGroup(id);

  const icons: any = {
    1: ShapeIcon,
    2: EyeIcon,
    3: Shape2Icon,
    4: IntegrityIcon,
    5: EmpathyIcon,
    6: ExpectationsIcon,
    7: TimeIcon,
    8: PersonalisationIcon,
    9: ResolutionIcon,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.head}>{t(group.title)}</Typography>
      </Grid>
      <Grid item xs={12}>
        {Object.values(group.categories).map((category: any) => (
          <Button
            className={classes.button}
            key={category.id}
            text={t(category.title)}
            onClick={() =>
              setCategoryGroup({ category: category.id, group: group.id })
            }
            variant="contained"
            color="primary"
            startIcon={icons[category.image ?? 1]}
            /* startIcon={<FilterDrama />} */
          />
        ))}
      </Grid>
    </Grid>
  );
});
