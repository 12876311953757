import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useApi } from "model/hooks/useApi";
import { AppBarLayout, Layout } from "components";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import ProgressContainer from "../../../components/Training/ProgressConteiner";
import AchievementImage from "../../../assets/images/achievement.svg";
import Header from "../Header";

const levels = [
  {
    title: "Stupeň I",
    description:
      "Možná pro Tebe bylo FHC DNA novinkou, ale není to vůbec poznat. " +
      "Jen tak dál, trénink dělá mistra!",
    image: AchievementImage,
    points: 40,
  },
  {
    title: "Stupeň II",
    description:
      "FHC DNA nasáváš jako houba a nebojíš se výroky zkoušet v praxi. Skvělé, brzy budeš ostřílený mazák! ",
    image: AchievementImage,
    points: 90,
  },
  {
    title: "Stupeň III",
    description:
      "FHC DNA důvěrně znáš a málokdy Tě něco překvapí. Nepolevuj, k mistrovství už Ti chybí jen kousek!",
    image: AchievementImage,
    points: 133,
  },
  {
    title: "Stupeň IV",
    description:
      "Žádný výrok pro Tebe není problém a i Chuck Norris si kvůli FHC DNA k Tobě chodí pro radu. Gratulace!",
    image: AchievementImage,
    points: 183,
  },
];

const ChallengeProgressPersonal: React.FC = () => {
  const { t } = useTranslation();
  const api = useApi();

  const [points, setPoints] = useState<number>(0);

  React.useEffect(() => {
    api
      .get<number>(ApiUrlEnum.PERSONAL_POINTS)
      .then((response) => {
        if (response.data) {
          return setPoints(response.data);
        }
        return setPoints(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [api]);

  return (
    <AppBarLayout>
      <Layout
        top={
          <>
            <Header
              leftText={t("challenge.progress.personal")}
              rightText={`(${points} bodů)`}
            />
            <Grid container style={{ marginTop: 30 }}>
              <Grid item>
                <ProgressContainer levels={levels} points={points} />
              </Grid>
            </Grid>
          </>
        }
      />
    </AppBarLayout>
  );
};

export default ChallengeProgressPersonal;
