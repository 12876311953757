import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "@material-ui/core";
import moment from "moment";
import { AppBarLayout, ButtonsList, Layout } from "components";
import { RouteEnum } from "model/enums/RouteEnum";
import { useHistory } from "react-router-dom";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { IStatement } from "pages/Statements/statement";
import Header from "../Header";
import Statement from "./Statement";
import {
  STATEMENT_ANSWER_MAX_LENGTH,
  STATEMENT_ANSWER_MIN_LENGTH,
} from "./constants";
import Review from "./Review";

const ChallengeStatements: React.FC = () => {
  const api = useApi();
  const [error, setError] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [statement, setStatement] = useState({} as IStatement);
  const [showReview, setShowReview] = useState(false);
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const history = useHistory();

  const fetchQueue = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/statements/queue`);
      if (response.data.length > 0) {
        // using the first statements in the queue
        setStatement(response.data[0]);
        setText("");
        setShowReview(false);
        setIsLoading(false);
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    // redirect back, empty queue or 403
    setIsLoading(false);
    return history.push(RouteEnum.CHALLENGE_DONE);
  }, [api, history]);

  useEffect(() => {
    fetchQueue();
  }, [fetchQueue]);

  const onTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let { value } = e.target;

    value = value.replace("=", " ");

    if (value.length > STATEMENT_ANSWER_MAX_LENGTH) {
      setText(value.slice(0, STATEMENT_ANSWER_MAX_LENGTH));
      return;
    }

    setText(value);
  };

  const handleSendAnswer = async () => {
    setIsLoading(true);
    setError({});
    try {
      const response = await api.post(ApiUrlEnum.STATEMENTS_POST_ANSWER, {
        text,
        statement: statement._id,
      });
      if (response.data && response.data._id) {
        // new answer has been saved successfully, show done page
        setIsLoading(false);
        await fetchQueue();
      }
    } catch (e) {
      console.log(e);
      setError(e);
    }
    setIsLoading(false);
    return false;
  };

  const handleCloseModal = () => {
    return setError({});
  };

  return (
    <AppBarLayout>
      <Layout
        top={
          <Header
            rightText={moment(statement?.date).format("D. M.")}
            leftText={t("challenge.statements.header")}
          />
        }
      >
        {!!error?.message && (
          <Modal
            open={!!error}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {error.message}
          </Modal>
        )}
        {showReview ? (
          <>
            <Review text={text} statement={statement} />
            <ButtonsList
              buttons={[
                {
                  key: 1,
                  text: isLoading
                    ? t("loading")
                    : t("challenge.buttons.finishChallenge"),
                  variant: "contained",
                  color: "primary",
                  onClick: () => handleSendAnswer(),
                },
              ]}
            />
          </>
        ) : (
          <>
            <Statement
              text={text}
              onTextChange={onTextChange}
              statement={statement}
              isLoading={isLoading}
            />
            <ButtonsList
              buttons={[
                {
                  key: 1,
                  onClick: () => setShowReview(true),
                  variant: "contained",
                  color: "primary",
                  text: t("challenge.buttons.sendComment"),
                  disabled: text.length < STATEMENT_ANSWER_MIN_LENGTH,
                },
              ]}
            />
          </>
        )}
      </Layout>
    </AppBarLayout>
  );
};

export default ChallengeStatements;
