export enum RouteEnum {
  HOMEPAGE = "/",
  TUTORIAL = "/tutorial",
  ACCESSIBILITY = "/accessibility",
  STATEMENTS = "/statements",
  // STATEMENTS_ALL = `/statements/all`,
  // STATEMENTS_CATEGORY = "/statements/:group/:category",
  CHALLENGE = "/challenge",
  CHALLENGE_TRAINING = "/challenge/training",
  CHALLENGE_STATEMENTS = "/challenge/statements",
  // CHALLENGE_STATEMENTS_REVIEW = "/challenge/statements/review",
  REVIEW_DONE = "/challenge/statements/review/done",
  CHALLENGE_DONE = "/challenge/statements/done",
  CHALLENGE_RATING = "/challenge/rating",
  CHALLENGE_RESULTS = "/challenge/results",
  CHALLENGE_RESULTS_DONE = "/challenge/results/done",
  CHALLENGE_PROGRESS_PERSONAL = "/challenge/progress/personal",
  CHALLENGE_PROGRESS_TEAM = "/challenge/progress/team",
  INITIATIVES = "/initiatives",
  INITIATIVES_NEW = "/initiatives/new",
  INITIATIVES_TEAMS = "/initiatives/teams",
  INITIATIVES_DETAIL = "/initiatives/detail/:id",
  INITIATIVES_COMMENT = "/initiatives/comment/:id",
  ADMIN_DASHBOARD = "/admin",
  ADMIN_IMPORTS = "/admin/imports",
  ADMIN_EXPORTS = "/admin/exports",
  ADMIN_SETTINGS = "/admin/settings",
  ADMIN_ANALYTICS = "/admin/analytics",
  ADMIN_AUDIT = "/admin/audit",
  AUTH_LOGIN = "/auth/login",
  FREE_TRAININGS = "/free-trainings",
}
