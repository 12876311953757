import React, { FC, memo } from "react";
import { Route, Switch } from "react-router-dom";
import { RouteEnum } from "../../model/enums/RouteEnum";
import Imports from "./Imports";
import Exports from "./Exports";
import Settings from "./Settings";
import Audits from "./Audits";
import Analytics from "./Analytics";
import Dashboard from "./Dashboard";
import { AdminLayout } from "../../components/AdminLayout";

const Admin: FC = memo(() => {
  return (
    <AdminLayout>
      <Switch>
        <Route path={RouteEnum.ADMIN_IMPORTS}>
          <Imports />
        </Route>
        <Route path={RouteEnum.ADMIN_EXPORTS}>
          <Exports />
        </Route>
        <Route path={RouteEnum.ADMIN_SETTINGS}>
          <Settings />
        </Route>
        <Route path={RouteEnum.ADMIN_AUDIT}>
          <Audits />
        </Route>
        <Route path={RouteEnum.ADMIN_ANALYTICS}>
          <Analytics />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </AdminLayout>
  );
});

export default Admin;
