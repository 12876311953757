import React from "react";
import { Card as MUICard } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 5px 10px #0000001A",
    padding: theme.spacing(2),
  },
}));

const Card: React.FC<{ className?: string }> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <MUICard className={[className, classes.root].join(" ")}>
      {children}
    </MUICard>
  );
};

export default Card;
