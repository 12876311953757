import React from "react";
import { ITeam } from "../../pages/Initiatives/initiative";

export const TeamsContext = React.createContext<{
  team: ITeam | undefined;
  setTeam: (team?: ITeam) => void;
}>({
  team: undefined,
  setTeam: () => {},
});
