import axios, { AxiosInstance } from "axios";
import React from "react";
import { ApiErrorCodeEnum } from "model/enums/ApiErrorCodeEnum";
import { RouteEnum } from "model/enums/RouteEnum";

export const useApi = (): AxiosInstance => {
  return React.useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_PREFIX,
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const statusCode = error.response?.data?.statusCode as
          | undefined
          | number;

        if (statusCode === ApiErrorCodeEnum.UNAUTHORIZED) {
          window.location.href = RouteEnum.AUTH_LOGIN;
          return;
        }

        throw error;
      }
    );

    return axiosInstance;
  }, []);
};
