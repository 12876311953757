import React from "react";
import { Rating } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { StarBorder } from "@material-ui/icons";
import { IStarRatingProps } from "components/StarRating/star-rating";

const useStyles = makeStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary.main,
  },
  iconHover: {
    color: theme.palette.primary.main,
  },
  iconEmpty: {
    color: theme.palette.primary.main,
  },
  sizeLarge: {
    fontSize: "3rem",
  },
}));

export const StarRating: React.FC<IStarRatingProps> = ({
  name,
  defaultValue = 0,
  onChange,
}) => {
  const classes = useStyles();
  const readOnly = typeof onChange === "undefined";
  return (
    <Rating
      defaultValue={defaultValue}
      size="large"
      classes={classes}
      emptyIcon={<StarBorder fontSize="inherit" />}
      onChange={readOnly ? undefined : (e, value) => onChange!(value)}
      readOnly={readOnly}
      name={name}
    />
  );
};
