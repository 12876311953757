import React from "react";
import { AppBarLayout, ButtonsList, Layout } from "components";
import { Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  IInitiativeEntity,
  IInitiativeResponseEntity,
} from "pages/Initiatives/initiative";
import FullPageLoader from "components/FullPageLoader";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import Fields from "pages/Initiatives/Fields";
import { RouteEnum } from "model/enums/RouteEnum";
import { IStatement } from "pages/Statements/statement";

export const Comment: React.FC = () => {
  const api = useApi();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initiative, setInitiative] = React.useState<IInitiativeEntity>();
  const [statement, setStatement] = React.useState<IStatement>();
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    api
      .get<IInitiativeResponseEntity>(`${ApiUrlEnum.INITIATIVES_DETAIL}/${id}`)
      .then((response) => setInitiative(response.data.initiativeDetail))
      .catch((err) => console.error(err));
  }, [id, api]);

  const submit = () => {
    setIsSubmitting(true);
    api
      .post(ApiUrlEnum.COMMENTS, {
        initiative: initiative?._id,
        statement: statement?.id,
        text,
      })
      .then(() => history.push(RouteEnum.INITIATIVES))
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };

  if (!initiative || isSubmitting) {
    return <FullPageLoader />;
  }

  return (
    <AppBarLayout>
      <Layout
        top={<Typography variant="subtitle1">{initiative.name}</Typography>}
      >
        <Fields
          statement={statement}
          setStatement={setStatement}
          text={text}
          setText={setText}
        />
        <ButtonsList
          buttons={[
            {
              key: 1,
              variant: "contained",
              color: "primary",
              text: "Odeslat komentář",
              disabled: !text || !statement,
              onClick: submit,
            },
          ]}
        />
      </Layout>
    </AppBarLayout>
  );
};
