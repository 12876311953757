import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { Link as RouterLink } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { ArrowForwardIos } from "@material-ui/icons";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AppBarLayout } from "../../components";
import carouselItems from "./carouselItems";
import Slide from "./Slide";
import { theme } from "../../theme";

const useStyles = makeStyles(() => ({
  carousel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  indicatorsContainer: {
    textAlign: "left",
  },
  indicator: {
    paddingRight: theme.spacing(1),
    "&::before": {
      content: '""',
      display: "block",
      minWidth: "10px",
      minHeight: "10px",
      background: "#EEEEEE",
      borderRadius: "50%",
    },
  },
  next: {
    right: 0,
    bottom: 0,
    top: "auto",
    height: "auto",
    display: "flex",
    cursor: "pointer",
  },
  nextLabel: {
    fontSize: 14,
  },
  nextIcon: {
    position: "relative",
    marginLeft: "5px",
    top: "2px",
  },
  activeIndicator: {
    "&::before": {
      background: "#64B5F6",
      minWidth: "30px",
      borderRadius: "8px",
    },
  },
}));

const Tutorial: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    const cookies = new Cookies();
    const expDate = new Date();
    // year expire
    expDate.setTime(expDate.getTime() + 1 * 3600 * 24 * 365 * 1000);
    cookies.set(
      process.env.REACT_APP_TUTORIAL_COOKIE ?? "tutorialPassed",
      "1",
      {
        path: "/",
        expires: expDate,
      }
    );
  }, []);

  return (
    <AppBarLayout
      showMenu={false}
      customLink={
        <Link
          variant="body1"
          component={RouterLink}
          to="/"
          color="inherit"
          underline="none"
        >
          {t("tutorial.skip")}
        </Link>
      }
    >
      <Carousel
        className={classes.carousel}
        animation="slide"
        autoPlay={false}
        cycleNavigation={false}
        IndicatorIcon={false}
        navButtonsWrapperProps={{
          className: classes.next,
          style: {},
        }}
        NavButton={({ onClick, next, prev }) => {
          if (next) {
            return (
              <Typography
                variant="body2"
                className={classes.nextLabel}
                onClick={() => onClick()}
              >
                {t("tutorial.next")}
                <ArrowForwardIos
                  fontSize="inherit"
                  className={classes.nextIcon}
                />
              </Typography>
            );
          }

          return <></>;
        }}
        indicatorIconButtonProps={{
          className: classes.indicator,
          style: {},
        }}
        activeIndicatorIconButtonProps={{
          className: classes.activeIndicator,
          style: {},
        }}
        indicatorContainerProps={{
          className: classes.indicatorsContainer,
          style: {},
        }}
      >
        {carouselItems.map((item) => (
          <Slide key={item.key} item={item} />
        ))}
      </Carousel>
    </AppBarLayout>
  );
};

export default Tutorial;
