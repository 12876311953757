import React, { FC, memo, useContext, useEffect, useState } from "react";
import { AppBarLayout, ButtonsList, Layout, SearchField } from "components";
import { Link, useHistory } from "react-router-dom";
import { useApi } from "model/hooks/useApi";
import {
  AccordionDetails,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { RouteEnum } from "model/enums/RouteEnum";
import { UserContext } from "model/contexts/UserContext";
import { TeamsContext } from "model/contexts/TeamsContext";
import { IUserEntity } from "model/interfaces/api-entity";
import {
  IGroupedInitiative,
  IInitiativeEntity,
  IStatus,
} from "pages/Initiatives/initiative";
import { InitiativeStatusEnum } from "pages/Initiatives/Detail/InitiativeStatusEnum";
import { Description } from "./Description";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  count: {
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    padding: 0,
    backgroundColor:
      theme.palette.type === "light" ? "#F8F8F8" : theme.palette.info.main,
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderColor: theme.palette.type === "light" ? "#EEEEEE" : "#000",
    borderWidth: "2px",
  },
  searchField: {
    marginBottom: "16px",
    marginTop: "16px",
  },
  accordionWrapper: {
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.16)",
  },
  listItem: {
    width: "100%",
  },
  listItemButton: {
    margin: "auto",
    padding: "5px",
    lineHeight: "0.8rem",
    fontSize: "0.8rem",
  },
  gridItem: {
    margin: "auto",
  },
}));

const Accordion = withStyles({
  root: {
    padding: 0,
    "&$expanded": {
      margin: 0,
    },
    list: {
      width: "100%",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: "48px !important",
    maxHeight: "48px !important",
  },
  expanded: {
    "&:before": {
      top: "-1px",
      left: 0,
      right: 0,
      height: "1px",
      content: "''",
      opacity: "0.5",
      position: "absolute",
      transition:
        "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
})(MuiAccordionSummary);

const isMine = (item: IInitiativeEntity, user?: IUserEntity) =>
  user?._id === item.author;

const redirectToDetail = (item: IInitiativeEntity) =>
  RouteEnum.INITIATIVES_DETAIL.replace(":id", item._id.toString());
const redirectToCommentForm = (item: IInitiativeEntity) =>
  RouteEnum.INITIATIVES_COMMENT.replace(":id", item._id.toString());

const initiativeRedirect = (
  item: IInitiativeEntity,
  user?: IUserEntity
): string => {
  // Arhived or Done can only be viewed
  if (
    [InitiativeStatusEnum.DONE, InitiativeStatusEnum.ARCHIVED].includes(
      item.status
    )
  ) {
    return redirectToDetail(item);
  }

  // If I already commented, I can only view
  if (item.comments.findIndex((com) => com.author === user?._id) !== -1) {
    return redirectToDetail(item);
  }

  // Otherwise I can add comment
  return redirectToCommentForm(item);
};

export const Overview: FC = memo(() => {
  const classes = useStyles();
  const api = useApi();
  const history = useHistory();
  const user = useContext(UserContext);
  const { team } = useContext(TeamsContext);
  const teamId = team ? team._id : undefined;

  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [initiatives, setInitiatives] = useState<IGroupedInitiative[]>([]);
  const [allInitiatives, setAllInitiatives] = useState<IInitiativeEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    const fetchInitiatives = () => {
      Promise.all([
        api.get(
          teamId
            ? `${ApiUrlEnum.INITIATIVES_GROUPED}/${teamId}`
            : ApiUrlEnum.INITIATIVES_GROUPED
        ),
        api.get(ApiUrlEnum.INITIATIVES_STATUSES_LIST),
      ])
        .then((response) => {
          const initiativeData = response[0].data;
          const statusesData = response[1].data;
          let initiativesFull: IGroupedInitiative[] = [];

          statusesData.forEach((s: any) => {
            const isExist = initiativeData.find((i: any) => {
              if (i._id === s._id) {
                initiativesFull.push(i);
                return true;
              }
              return false;
            });

            // if some status is empty and API is not returning data, fill that with empty object
            if (!isExist && s._id !== "NEW") {
              initiativesFull.push({
                _id: s._id,
                count: 0,
                items: [],
              });
            }
          });

          // find IN_PROGRESS group
          const inProgressItems = initiativesFull.find(
            (i) => i._id === InitiativeStatusEnum.IN_PROGRESS
          );

          // find only items from colleagues without user comment
          const newItems = inProgressItems?.items?.filter(
            (i) =>
              i.author.toString() !== user?._id &&
              !i.comments.find((c) => c.author.toString() === user?._id)
          );

          // items used in NEW GROUP to remove from IN_PROGRESS
          const toReduce = newItems?.map((ni) => ni._id);
          const reducedInProgressItems = inProgressItems?.items?.filter(
            (i) => toReduce?.includes(i._id) === false
          );

          // reduce IN_PROGRESS group from items moved into NEW group
          initiativesFull = initiativesFull.map((i) => {
            if (i._id === InitiativeStatusEnum.IN_PROGRESS) {
              return {
                ...i,
                count: reducedInProgressItems?.length ?? 0,
                items: reducedInProgressItems ?? [],
              };
            }
            return i;
          });

          // NEW group is not returned from backend
          initiativesFull = [
            {
              _id: InitiativeStatusEnum.NEW,
              count: newItems?.length ?? 0,
              items: newItems ?? [],
            },
            ...initiativesFull,
          ];

          setInitiatives(initiativesFull);
          setStatuses(statusesData);
          const all: IInitiativeEntity[] = [];
          response[0].data.forEach((g: IGroupedInitiative) => {
            g.items.forEach((i: IInitiativeEntity) => {
              all.push(i);
            });
          });
          setAllInitiatives(all);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    fetchInitiatives();
    const interval = setInterval(() => fetchInitiatives(), 30000);
    // destroy interval on unmount
    return () => clearInterval(interval);
  }, [api, user, teamId]);

  const expandAllAccordions = () => setExpanded(initiatives.map((i) => i._id));

  const closeAllAccordions = () => setExpanded([]);

  const onSearch = (term: string) => {
    setSearchTerm(term);

    if (term === "") {
      return closeAllAccordions();
    }

    return expandAllAccordions();
  };

  const filterResult = (initiative: IGroupedInitiative): boolean => {
    if (searchTerm === "") return true;

    return (
      initiative.items.findIndex((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) !== -1
    );
  };

  const filterItem = (initiativeItem: IInitiativeEntity): boolean => {
    if (searchTerm === "") return true;

    return initiativeItem.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const handleAccordionChange =
    (id: string) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      event: React.ChangeEvent<{}>,
      isExpanded: boolean
    ): void => {
      if (isExpanded && !expanded.includes(id)) {
        setExpanded((current) => [...current, id]);
      }

      if (!isExpanded && expanded.includes(id)) {
        setExpanded((current) => current.filter((c) => c !== id));
      }
    };

  return (
    <AppBarLayout showMenu>
      <Layout top={<Description />}>
        <div className={classes.searchField}>
          <SearchField onSearch={onSearch} />
        </div>
        <div className={classes.accordionWrapper}>
          {initiatives?.filter(filterResult).map((i) => (
            <Accordion
              expanded={expanded.includes(i._id)}
              key={i._id}
              onChange={handleAccordionChange(i._id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container>
                  <Grid xs={10} item>
                    <Typography className={classes.heading}>
                      {statuses.find((s) => s._id === i._id)?.name}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Typography className={classes.count}>{i.count}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <List className={classes.listItem}>
                  {i.items
                    ?.filter(filterItem)
                    .map((item: IInitiativeEntity, itemIndex) => (
                      <React.Fragment key={item._id}>
                        <ListItem
                          className={classes.listItem}
                          component={Link}
                          to={initiativeRedirect(item, user)}
                        >
                          <ListItemText className={classes.listItem}>
                            <Grid container>
                              <Grid
                                xs={isMine(item, user) ? 9 : 12}
                                item
                                className={classes.gridItem}
                              >
                                {item.name}
                              </Grid>
                              {isMine(item, user) && (
                                <Grid xs={3} item className={classes.gridItem}>
                                  <ButtonsList
                                    buttons={[
                                      {
                                        key: 1,
                                        variant: "contained",
                                        color: "primary",
                                        text: "Moje",
                                        className: classes.listItemButton,
                                      },
                                    ]}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </ListItemText>
                        </ListItem>
                        {itemIndex + 1 < i.items.length && <Divider />}
                      </React.Fragment>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <ButtonsList
          buttons={[
            {
              key: 1,
              onClick: () => history.push(RouteEnum.INITIATIVES_NEW),
              variant: "contained",
              color: "primary",
              text: "Nová Iniciativa",
            },
          ]}
        />
      </Layout>
    </AppBarLayout>
  );
});
