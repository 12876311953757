import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ButtonsList } from "../../../components";

const Analytics: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom component="h5">
              {t("admin.buttons.analytics")}
            </Typography>
            <ButtonsList
              buttons={[
                {
                  key: 10,
                  onClick: () => {
                    window.location.href = `${process.env.REACT_APP_API_PREFIX}/users/download-users-report`;
                  },
                  variant: "contained",
                  color: "primary",
                  text: t("admin.analytics.usersReport"),
                },
                {
                  key: 20,
                  onClick: () => {
                    window.location.href = `${process.env.REACT_APP_API_PREFIX}/users/download-teams-report`;
                  },
                  variant: "contained",
                  color: "primary",
                  text: t("admin.analytics.teamsReport"),
                },
                {
                  key: 30,
                  onClick: () => {
                    window.location.href = `${process.env.REACT_APP_API_PREFIX}/users/download-answers-report`;
                  },
                  variant: "contained",
                  color: "primary",
                  text: t("admin.analytics.answersReport"),
                },
                {
                  key: 40,
                  onClick: () => {
                    window.location.href = `${process.env.REACT_APP_API_PREFIX}/users/download-trainings-report`;
                  },
                  variant: "contained",
                  color: "primary",
                  text: t("admin.analytics.trainingsReport"),
                },
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default Analytics;
