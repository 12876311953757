import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
}));

export const Description: FC = memo(() => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.description}>
          {t("statements.description")}
        </Typography>
      </Grid>
    </Grid>
  );
});
