import React from "react";
import { Grid } from "@material-ui/core";
import ProgressCard from "./ProgressCard";

interface IProgressContainer {
  levels: {
    title: string;
    description: string;
    image: string;
    points: number;
  }[];
  points: number;
}

const ProgressContainer: React.VFC<IProgressContainer> = ({
  levels,
  points,
}) => (
  <Grid container spacing={3} direction="column">
    {levels.map((level) => (
      <ProgressCard
        title={level.title}
        description={level.description}
        image={level.image}
        disabled={level.points > points}
      />
    ))}
  </Grid>
);

export default ProgressContainer;
