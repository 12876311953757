import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IStatementListItemProps } from "pages/Initiatives/initiative";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  rightArrowIconWrapper: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: theme.spacing(2),
  },
  rightArrowIcon: {
    fontSize: "1rem",
    color: theme.palette.primary.dark,
  },
}));

const StatementsListItem: React.FC<IStatementListItemProps> = ({
  item,
  index,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.root}
      onClick={onClick}
      alignItems="flex-start"
      selected={index % 2 === 1}
    >
      <ListItemIcon>{item.count}x</ListItemIcon>
      <ListItemText primary={item.statement.subject} />
      <ListItemIcon className={classes.rightArrowIconWrapper}>
        <ArrowForwardIos className={classes.rightArrowIcon} />
      </ListItemIcon>
    </ListItem>
  );
};

export default StatementsListItem;
