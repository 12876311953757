import React from "react";
import { IInitiativeDetailHeaderProps } from "pages/Initiatives/initiative";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import BackButton from "components/BackButton";

const useStyles = makeStyles(() => ({
  textRight: {
    textAlign: "right",
  },
  backButton: {
    textTransform: "none",
  },
}));

const Header: React.FC<IInitiativeDetailHeaderProps> = ({
  initiative,
  goBack,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={goBack ? 10 : 12}>
        <Typography variant="subtitle1">{initiative.name}</Typography>
      </Grid>
      {goBack && (
        <Grid item xs={2} className={classes.textRight}>
          <Button
            className={classes.backButton}
            variant="text"
            onClick={goBack}
          >
            <BackButton />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
