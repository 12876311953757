import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  backArrow: {
    fontSize: "10px",
  },
  backArrowWrapper: {
    display: "inline",
  },
  backButton: {
    textTransform: "none",
  },
}));

const BackButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.backButton} variant="text" onClick={onClick}>
      <div className={classes.backArrowWrapper}>
        <ArrowBackIos className={classes.backArrow} />
      </div>
      <u>{t("challenge.progress.back")}</u>
    </Button>
  );
};

export default BackButton;
