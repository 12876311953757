import React, { FC, memo, useEffect, useState } from "react";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { Overview } from "./Overview";
import { StatementsList } from "./List";
import { StatementsSectionEnum } from "./StatementsSectionEnum";
import {
  IStatement,
  IStatementCategory,
  IStatementsData,
  StatementGroup,
} from "./statement";
import { getAllStatements } from "./services";

const Statements: FC = memo(() => {
  const api = useApi();
  const [data, setData] = useState({} as IStatementsData);
  const [groups, setGroups] = useState([] as any);
  const [allStatements, setAllStatements] = useState([] as IStatement[]);
  const [section, setSection] = React.useState<StatementsSectionEnum>(
    StatementsSectionEnum.OVERVIEW
  );
  const [category, setCategory] = React.useState<
    { category: string; group: StatementGroup } | undefined
  >();

  useEffect(() => {
    const fetchStatements = async () => {
      try {
        const response = await api.get(ApiUrlEnum.STATEMENTS_GROUPED);
        const allStatementsData: IStatementsData = response.data;
        if (allStatementsData) {
          setData(allStatementsData);
          setGroups([
            ...Object.values(allStatementsData).map(
              (statementCategory: IStatementCategory) => ({
                id: statementCategory.id,
              })
            ),
          ]);
          setAllStatements(getAllStatements(allStatementsData));

          return true;
        }
      } catch (e) {
        console.log(e);
      }

      return false;
    };

    fetchStatements();
  }, [api]);

  if (typeof category !== "undefined") {
    return (
      <StatementsList
        allStatements={data}
        category={category.category}
        group={category.group}
        goBack={() => {
          setSection(StatementsSectionEnum.OVERVIEW);
          setCategory(undefined);
        }}
      />
    );
  }

  if (section === StatementsSectionEnum.ALL) {
    return (
      <StatementsList
        allStatements={data}
        goBack={() => {
          setSection(StatementsSectionEnum.OVERVIEW);
          setCategory(undefined);
        }}
      />
    );
  }

  return (
    <Overview
      setSection={setSection}
      setCategory={setCategory}
      data={data}
      groups={groups}
      allStatements={allStatements}
    />
  );
});

export default Statements;
