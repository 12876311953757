import React, { FC, memo } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { witLeadingZero } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import { IStatement } from "pages/Statements/statement";
import { StatementsAsSelectContext } from "model/contexts/StatementsAsSelectContext";

const useStyles = makeStyles((theme) => ({
  index: {
    fontWeight: 600,
    color: theme.palette.text.disabled,
  },
  selectableItem: {
    cursor: "pointer",
  },
}));

interface IProps extends IStatement {
  index: number;
}
export const Item: FC<IProps> = memo((statement) => {
  const classes = useStyles();
  const onSelected = React.useContext(StatementsAsSelectContext);

  return (
    <ListItem
      key={statement.id}
      className={onSelected ? classes.selectableItem : ""}
      alignItems="flex-start"
      selected={statement.index % 2 === 0}
      onClick={onSelected ? () => onSelected(statement) : undefined}
    >
      <ListItemIcon>
        <Typography className={classes.index}>
          {witLeadingZero(statement.index, 2)}
        </Typography>
      </ListItemIcon>
      <ListItemText primary={statement.text} />
    </ListItem>
  );
});
