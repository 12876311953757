import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },

  topWrapper: {},
  bottomWrapper: {},
});

interface ILayoutProps {
  top?: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ top, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {top && <div className={classes.topWrapper}>{top}</div>}
      {children && <div className={classes.bottomWrapper}>{children}</div>}
    </div>
  );
};
