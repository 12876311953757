import React, {
  BaseSyntheticEvent,
  FC,
  memo,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import {
  DatePicker,
  MaterialUiPickersDate,
  MuiPickersUtilsProvider,
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";

const Settings: FC = memo(() => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [squads, setSquads] = useState([] as ISquad[]);
  const [challenge, setChallenge] = useState({} as IChallenge);
  const [filter, setFilter] = useState<number[]>([]);

  useEffect(() => {
    const fetchSquads = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}/squads`
        );
        setSquads(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchSquads();

    const fetchChallenge = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}/challenges`
        );
        setChallenge(response.data[0]);
      } catch (e) {
        console.log(e);
      }
    };

    fetchChallenge();
  }, []);

  const handleUpdateSquad = (e: BaseSyntheticEvent) => {
    const selectedSquad: number = e.target.value;
    const currentSquads = challenge.squads;
    if (challenge.squads.includes(selectedSquad)) {
      // removing an existing assoc
      setChallenge({
        ...challenge,
        squads: currentSquads.filter((s: number) => s !== selectedSquad),
      });
    } else {
      // adding a new assoc
      currentSquads.push(selectedSquad);
      setChallenge({
        ...challenge,
        squads: currentSquads,
      });
    }
  };

  const handleSearchUnits = (e: BaseSyntheticEvent) => {
    setFilter(
      squads
        .filter((s) => s.title?.indexOf(e.target.value) !== -1)
        .map((s) => s._id)
    );
  };

  const handleSelectAll = () => {
    setChallenge({
      ...challenge,
      squads: squads.map((s) => s._id),
    });
  };

  const handleUnselectAll = () => {
    setChallenge({
      ...challenge,
      squads: [],
    });
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_PREFIX}/challenges/${challenge._id}`,
        {
          ...challenge,
        }
      );
      console.log(response);
    } catch (ex) {
      setError(`Objevil se problém: ${ex.message}`);
      console.log(ex);
    } finally {
      setSuccess(true);
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Grid container spacing={3}>
      {success && (
        <Grid item xs={12}>
          <Alert severity="success">Změny byly úspěšně uloženy...</Alert>
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <form noValidate autoComplete="off" onSubmit={handleSave}>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <Typography variant="h5" gutterBottom component="h5">
                    {t("admin.buttons.settings")}
                  </Typography>
                  <Grid item xs={12} style={{ marginBottom: 15 }}>
                    <FormGroup>
                      {"active" in challenge && (
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={challenge.active}
                            />
                          }
                          label={t("admin.settings.challenge.isActive")}
                          value={challenge.active}
                          onChange={() =>
                            setChallenge({
                              ...challenge,
                              active: !challenge?.active,
                            })
                          }
                        />
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 15 }}>
                    <FormGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          format="dd.MM.yyyy"
                          required
                          label={t("admin.settings.dateFrom")}
                          value={challenge.dateFrom}
                          fullWidth
                          onChange={(val: MaterialUiPickersDate) =>
                            setChallenge({
                              ...challenge,
                              dateFrom: moment(val).format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          required
                          autoOk
                          format="dd.MM.yyyy"
                          label={t("admin.settings.dateUntil")}
                          value={challenge.dateUntil}
                          fullWidth
                          onChange={(val: MaterialUiPickersDate) =>
                            setChallenge({
                              ...challenge,
                              dateUntil: moment(val).format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="h5" gutterBottom component="h5">
                    {t("admin.settings.squads")}
                  </Typography>
                  <TextField
                    style={{ marginBottom: 15, marginTop: 5 }}
                    size="small"
                    fullWidth
                    id="filled-search"
                    label="Vyhledávání"
                    type="search"
                    variant="outlined"
                    onChange={handleSearchUnits}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Grid container>
                    <Grid
                      item
                      md={12}
                      style={{ marginBottom: 10, fontSize: "14px" }}
                    >
                      <Button
                        size="small"
                        component={Link}
                        onClick={handleSelectAll}
                      >
                        Vybrat vše
                      </Button>{" "}
                      /{" "}
                      <Button
                        size="small"
                        component={Link}
                        onClick={handleUnselectAll}
                      >
                        Zrušit vše
                      </Button>
                    </Grid>
                  </Grid>

                  {"squads" in challenge &&
                    squads
                      ?.filter(
                        (s: any) => filter.length < 1 || filter.includes(s._id)
                      )
                      .map((s: ISquad) => (
                        <FormGroup>
                          <FormControlLabel
                            key={s._id}
                            control={
                              <Switch
                                checked={
                                  !!challenge?.squads?.find(
                                    (chSquad: number) => chSquad === s._id
                                  )
                                }
                                color="primary"
                              />
                            }
                            label={s.title}
                            value={s._id}
                            onChange={handleUpdateSquad}
                          />
                        </FormGroup>
                      ))}
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    {isLoading
                      ? "Loading..."
                      : t("admin.settings.challenge.submit")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default Settings;
