import React from "react";
import { StatementCard } from "components";
import { IResultStatementProps } from "pages/Challenge/challenge";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const ResultStatementCard: React.FC<IResultStatementProps> = ({
  results: { subject },
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatementCard>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t("challenge.results.statement")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">&quot;{subject}&quot;</Typography>
        </Grid>
      </StatementCard>
    </div>
  );
};

export default ResultStatementCard;
