import React from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { StarRating, StatementCard } from "components";
import { Help } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { IStatementRatingCardProps } from "pages/Challenge/challenge";

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    marginBottom: "5px",
    marginLeft: theme.spacing(2),
    color: theme.palette.grey.A200,
  },
}));

const StatementRatingCard: React.FC<IStatementRatingCardProps> = ({
  answer,
  onRating,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <StatementCard>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {t("challenge.rating.statement")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{answer.statement.subject}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {t("challenge.rating.accuracy")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">&quot;{answer.text}&quot;</Typography>
      </Grid>
      <Grid item xs={11}>
        <StarRating
          name="answer-rating"
          onChange={(value) => onRating(value)}
        />
        <Tooltip
          disableFocusListener
          enterTouchDelay={-300}
          arrow
          title={`${t("challenge.rating.tooltip")}`}
          placement="top-start"
        >
          <Help className={classes.helpIcon} fontSize="small" />
        </Tooltip>
      </Grid>
    </StatementCard>
  );
};

export default StatementRatingCard;
