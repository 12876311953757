import React, { FC, memo } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar as MaterialAppBar,
  Box,
  Breadcrumbs,
  Link,
  Toolbar,
} from "@material-ui/core";
import { IAppBarProps } from "./app-bar";
import { Menu } from "./Menu";
import { getBreadcrumbs } from "./helpers";
import AppBarLine from "../../assets/images/appbar-line.svg";
import { HideAppBarContext } from "./HideAppBarContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    color: "white",
  },
  separator: {
    marginLeft: theme.spacing(0.8),
    fontSize: "0.8rem",
    color: theme.palette.info.main,
  },
  subRoute: {
    marginLeft: theme.spacing(0.8),
    fontSize: "1rem",
    color: theme.palette.info.main,
  },
  appBar: {
    position: "relative",
    margin: "0 0 33px 0",
    background: theme.palette.primary.main,

    "&::after": {
      position: "absolute",
      display: "block",
      width: "100%",
      top: "99%",
      left: 0,
      content: "''",
      height: "33px",
      backgroundImage: `url(${AppBarLine})`,
      backgroundSize: "auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 0 top",
    },
  },
}));

export const AppBar: FC<IAppBarProps> = memo(({ showMenu, customLink }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const hidden = React.useContext(HideAppBarContext);

  const breadcrumb = React.useMemo(() => getBreadcrumbs(pathname), [pathname]);

  if (hidden) {
    return null;
  }

  return (
    <MaterialAppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Box display="flex" flexGrow={1}>
          <Breadcrumbs
            separator={
              <ArrowForwardIosIcon
                className={classes.separator}
                fontSize="small"
              />
            }
            aria-label="breadcrumbs"
            className={classes.breadcrumbs}
          >
            <Link
              variant="h6"
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              FHC DNA
            </Link>
            {breadcrumb && (
              <Link
                key={breadcrumb.link}
                variant="h6"
                component={RouterLink}
                to={breadcrumb.link}
                color="inherit"
                underline="none"
                className={classes.subRoute}
              >
                {t(breadcrumb.label)}
              </Link>
            )}
          </Breadcrumbs>
        </Box>
        {customLink && customLink}
        {showMenu && <Menu />}
      </Toolbar>
    </MaterialAppBar>
  );
});
