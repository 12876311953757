import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  STATEMENT_ANSWER_MAX_LENGTH,
  STATEMENT_ANSWER_MIN_LENGTH,
} from "pages/Challenge/Statements/constants";
import { StatementCard } from "components";
import { IStatementProps } from "../challenge";

const useStyles = makeStyles((theme) => ({
  textAreaCounter: {
    textAlign: "right",
  },
  textAreaCounterTextDefault: {
    color: theme.palette.text.secondary,
  },
  textAreaCounterTextSuccess: {
    color: theme.palette.success.main,
  },
  textAreaCounterTextError: {
    color: theme.palette.error.main,
  },
}));

const Statement: React.FC<IStatementProps> = ({
  text,
  onTextChange,
  statement,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const textAreaCounterClass = React.useMemo(() => {
    if (text.length < STATEMENT_ANSWER_MIN_LENGTH) {
      return classes.textAreaCounterTextDefault;
    }

    if (text.length === STATEMENT_ANSWER_MAX_LENGTH) {
      return classes.textAreaCounterTextError;
    }

    return classes.textAreaCounterTextSuccess;
  }, [text, classes]);

  return (
    <StatementCard>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {t("challenge.statements.hint")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          {isLoading ? t("loading") : `"${statement.subject}"`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder={t("challenge.statements.textAreaLabel")}
          variant="outlined"
          onChange={onTextChange}
          value={text}
        />
        <div className={classes.textAreaCounter}>
          <Typography
            className={textAreaCounterClass}
            variant="overline"
          >{`${text.length}/${STATEMENT_ANSWER_MAX_LENGTH}`}</Typography>
        </div>
      </Grid>
    </StatementCard>
  );
};

export default Statement;
