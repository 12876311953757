import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import BackButton from "components/BackButton";

export const Header: FC<{ title: string; goBack: () => void }> = memo(
  ({ title, goBack }) => {
    const { t } = useTranslation();

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="subtitle1">{t(title)}</Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Typography variant="subtitle1">
            <BackButton onClick={goBack} />
          </Typography>
        </Grid>
      </Grid>
    );
  }
);
