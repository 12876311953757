import React from "react";
import { IResultRatedGroupProps } from "pages/Challenge/challenge";
import { StarRating } from "components";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, List as MaterialList } from "@material-ui/core";
import RatedGroupItem from "pages/Challenge/Results/RatedGroupItem";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  rating: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
  },
  card: {
    padding: 0,
  },
}));

const RatedGroup: React.FC<IResultRatedGroupProps> = ({
  results: { ratedAnswers },
}) => {
  const classes = useStyles();

  return (
    <>
      {ratedAnswers.map((rated) => (
        <div key={rated.rating} className={classes.root}>
          <Card elevation={4} className={classes.card}>
            <Grid item xs={12} className={classes.rating}>
              <StarRating
                name={`results-rating-${rated.rating}`}
                defaultValue={rated.rating}
              />
            </Grid>
            <MaterialList className={classes.list}>
              {rated.items.map((item, itemIndex) => (
                <RatedGroupItem
                  // eslint-disable-next-line
                  key={itemIndex}
                  answerText={item.text[0]}
                  answerIndex={itemIndex}
                />
              ))}
            </MaterialList>
          </Card>
        </div>
      ))}
    </>
  );
};
export default RatedGroup;
