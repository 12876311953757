import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { Button } from "components";
import { IInitiativeDetailActionsDialogProps } from "pages/Initiatives/initiative";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
  },
  discardButton: {
    backgroundColor: theme.palette.info.light,
  },
}));

const ActionsDialog: React.FC<IInitiativeDetailActionsDialogProps> = ({
  dialogType,
  close,
  confirm,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const baseTranslationPath = React.useMemo(
    () => `initiatives.detail.dialog.${dialogType}`,
    [dialogType]
  );

  return (
    <Dialog
      fullWidth
      classes={{ paper: classes.dialog }}
      open={!!dialogType}
      onClose={close}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t(`${baseTranslationPath}.header`, "")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t(`${baseTranslationPath}.subtitle`, "")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            text={t(`${baseTranslationPath}.accept`, "")}
            onClick={confirm}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.discardButton}
            text={t(`${baseTranslationPath}.dismiss`, "")}
            onClick={close}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ActionsDialog;
