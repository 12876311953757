import React, { FC, memo } from "react";
import { AppBar } from "components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "0px auto",
    maxWidth: "450px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  layout: {
    padding: theme.spacing(2),
    display: "flex",
    flex: 1,
  },
}));

interface IProps {
  showMenu?: boolean;
  customLink?: React.ReactNode;
}

export const AppBarLayout: FC<IProps> = memo(
  ({ children, showMenu, customLink }) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <AppBar showMenu={showMenu} customLink={customLink} />
        <div className={classes.layout}>{children}</div>
      </div>
    );
  }
);
