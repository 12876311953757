import React from "react";
import { ICommentsListItemProps } from "pages/Initiatives/initiative";
import { ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "model/contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  mineComment: {
    backgroundColor: theme.palette.info.light,
  },
}));

const CommentsListItem: React.FC<ICommentsListItemProps> = ({ comment }) => {
  const classes = useStyles();
  const user = React.useContext(UserContext);

  if (!user) return null;

  const isMine = user._id === comment.author;

  return (
    <ListItem
      className={isMine ? classes.mineComment : ""}
      alignItems="flex-start"
    >
      <ListItemText primary={comment.text} />
    </ListItem>
  );
};

export default CommentsListItem;
