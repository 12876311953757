import React, { FC, memo } from "react";
import { IStatement } from "pages/Statements/statement";
import { List } from "../List/List";

export const Search: FC<{
  term: string;
  allStatements: IStatement[];
}> = memo(({ term, allStatements }) => {
  const list = allStatements.filter((statement) =>
    statement.text?.toLowerCase().includes(term.toLowerCase())
  );

  if (term === "") return null;
  return <List data={list} />;
});
