import React, { FC, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button as MaterialButton,
  ButtonProps,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    paddingTop: 13,
    paddingBottom: 13,
    boxShadow: "0px 3px 3px #2196F326;",
  },
  badge: {
    display: "block",
    padding: "5px",
    lineHeight: "13px",
    borderRadius: "4px",
    backgroundColor: "white",
    position: "absolute",
    fontSize: "14px",
    color: theme.palette.primary.main,
    right: "10px",
    top: "50%",
    marginTop: "-10px",
    minWidth: "23px",
    height: "23px",
  },
}));

export interface IButton extends ButtonProps {
  text: string | React.ReactNode;
  key?: string | number;
  badge?: number;
  isLoading?: boolean;
}

export const Button: FC<IButton> = memo(
  ({ text, isLoading, badge, disabled, ...props }) => {
    const classes = useStyles();
    return (
      <MaterialButton
        disabled={isLoading || disabled}
        {...props}
        className={[
          classes.button,
          props.className ? props.className : undefined,
        ].join(" ")}
      >
        {isLoading === true ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            {text} {badge ? <span className={classes.badge}>{badge}</span> : ""}
          </>
        )}
      </MaterialButton>
    );
  }
);
