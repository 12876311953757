import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Homepage from "pages/Homepage";
import Challenge from "pages/Challenge";
import Statements from "pages/Statements";
import Admin from "pages/Admin";
import FullPageLoader from "components/FullPageLoader";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { RouteEnum } from "model/enums/RouteEnum";
import { useTheme } from "model/hooks/useTheme";
import { ThemeToggleContext } from "model/contexts/ThemeToggleContext";
import { UserContext } from "model/contexts/UserContext";
import { AuthEntity } from "model/interfaces/AuthEntity";
import { IUserEntity } from "model/interfaces/api-entity";
import Tutorial from "./pages/Tutorial";
import Accessibility from "./pages/Accessibility";
import AdminRoute from "./components/AdminRoute";
import Initiatives from "./pages/Initiatives";
import FreeTraining from "./pages/FreeTraining";

function App(): JSX.Element {
  const api = useApi();
  const { theme, toggle } = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState<IUserEntity>();

  /* React.useEffect(() => {
    api
      .get<AuthEntity>(ApiUrlEnum.AUTH_USER)
      .then((response) => {
        if (response) {
          setUser({
            ...response.data.userinfo,
            ...response.data.userDetails,
            allowedChallenge: response.data.activeChallenge
              ? response.data.activeChallenge._id
              : null,
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [api]); */

  useEffect(() => {
    const loadUser = async (withPreload = true) => {
      api
        .get<AuthEntity>(ApiUrlEnum.AUTH_USER)
        .then((response) => {
          if (response) {
            setUser({
              ...response.data.userinfo,
              ...response.data.userDetails,
              allowedChallenge: response.data.activeChallenge
                ? response.data.activeChallenge._id
                : null,
            });
          }
        })
        .finally(() => {
          if (withPreload) {
            setIsLoading(false);
          }
        });
    };

    loadUser();
    const interval = setInterval(() => loadUser(false), 20000);
    // destroy interval on unmount
    return () => clearInterval(interval);
  }, [api]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (user) {
    console.log(user);
    return (
      <UserContext.Provider value={user}>
        <ThemeProvider theme={theme.theme}>
          <CssBaseline />
          <ThemeToggleContext.Provider value={{ toggle, isDark: theme.isDark }}>
            <Router>
              <Switch>
                <AdminRoute path={RouteEnum.ADMIN_DASHBOARD}>
                  <Admin />
                </AdminRoute>
                <Route path={RouteEnum.FREE_TRAININGS}>
                  <FreeTraining />
                </Route>
                <Route path={RouteEnum.CHALLENGE}>
                  <Challenge />
                </Route>
                <Route path={RouteEnum.TUTORIAL}>
                  <Tutorial />
                </Route>
                <Route path={RouteEnum.ACCESSIBILITY}>
                  <Accessibility />
                </Route>
                <Route path={RouteEnum.INITIATIVES}>
                  <Initiatives />
                </Route>
                <Route path={RouteEnum.STATEMENTS}>
                  <Statements />
                </Route>
                <Route path={RouteEnum.HOMEPAGE}>
                  <Homepage />
                </Route>
              </Switch>
            </Router>
          </ThemeToggleContext.Provider>
        </ThemeProvider>
      </UserContext.Provider>
    );
  }

  return <></>;
}

export default App;
