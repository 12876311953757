import {
  IInitiativeEntity,
  IInitiativeStatementGroup,
} from "pages/Initiatives/initiative";

export const getCommentsGroupedByStatement = (
  initiative: IInitiativeEntity
): IInitiativeStatementGroup[] => {
  const out: IInitiativeStatementGroup[] = [];

  initiative.comments.forEach((comment) => {
    const existing = out.find(
      (grouped) => grouped.statement._id === comment.statement._id
    );

    if (existing) {
      existing.count += 1;
      existing.comments.push(comment);
      return;
    }

    out.push({
      statement: comment.statement,
      count: 1,
      comments: [comment],
    });
  });

  return out.sort((a, b) => (a.count <= b.count ? 1 : -1));
};
