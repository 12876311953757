import React from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card";
import { INewInitiativeFieldsProps } from "pages/Initiatives/initiative";
import { useTranslation } from "react-i18next";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import StatementsModal from "pages/Initiatives/Fields/StatementsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  textLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nameCounter: {
    textAlign: "right",
  },
  nameCounterTextDefault: {
    color: theme.palette.text.secondary,
  },
  nameCounterTextSuccess: {
    color: theme.palette.success.main,
  },
  nameCounterTextError: {
    color: theme.palette.error.main,
  },
  textAreaCounter: {
    textAlign: "right",
  },
  textAreaCounterTextDefault: {
    color: theme.palette.text.secondary,
  },
  textAreaCounterTextSuccess: {
    color: theme.palette.success.main,
  },
  textAreaCounterTextError: {
    color: theme.palette.error.main,
  },
  selectStatementButton: {
    fontSize: theme.typography.fontSize,
    textTransform: "none",
    fontWeight: "normal",
    justifyContent: "flex-start",
    minHeight: "60px",
    textAlign: "left",
  },
}));

const Fields: React.FC<INewInitiativeFieldsProps> = ({
  name,
  setName,
  text,
  setText,
  statement,
  setStatement,
}) => {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();
  const [statements, setStatements] = React.useState<any[]>([]);
  const [statementModalOpen, setStatementModalOpen] = React.useState(false);

  /**
   * TODO: Temp solution -> we are going to display Statements page
   * TODO: instead of simple select
   */
  React.useEffect(() => {
    api.get<any>(ApiUrlEnum.STATEMENTS_GROUPED).then((response) => {
      const out: any[] = [];
      const { data } = response;
      Object.keys(data).forEach((topKey) => {
        const { categories } = data[topKey];
        Object.keys(categories).forEach((categoryKey) => {
          const category = categories[categoryKey];
          out.push(...category.list);
        });
      });

      setStatements(out);
    });
  }, [api]);

  const nameCounterClass = React.useMemo(() => {
    if (typeof name === "undefined") return "";

    if (name.length < 1) {
      return classes.textAreaCounterTextDefault;
    }

    if (name.length === 61) {
      return classes.textAreaCounterTextError;
    }

    return classes.textAreaCounterTextSuccess;
  }, [name, classes]);

  const textAreaCounterClass = React.useMemo(() => {
    if (text.length < 1) {
      return classes.textAreaCounterTextDefault;
    }

    if (text.length === 281) {
      return classes.textAreaCounterTextError;
    }

    return classes.textAreaCounterTextSuccess;
  }, [text, classes]);

  return (
    <>
      <StatementsModal
        isOpen={statementModalOpen}
        close={() => setStatementModalOpen(false)}
        onSelected={(selectedStatement) => {
          setStatement(selectedStatement);
          setStatementModalOpen(false);
        }}
      />
      <Card>
        <div className={classes.root}>
          {typeof name !== "undefined" && typeof setName !== "undefined" && (
            <>
              <TextField
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value.replace("=", " "))}
                label="Název Iniciativy"
                fullWidth
                inputProps={{ maxLength: 60 }}
              />
              <div className={classes.nameCounter}>
                <Typography
                  className={nameCounterClass}
                  variant="overline"
                >{`${name.length}/60`}</Typography>
              </div>
            </>
          )}
          <Button
            className={classes.selectStatementButton}
            onClick={() => setStatementModalOpen(true)}
            variant="outlined"
            fullWidth
          >
            {typeof statement !== "undefined"
              ? statement?.text
              : "Vyberte výrok"}
          </Button>
          <Typography className={classes.textLabel} variant="subtitle1">
            {typeof setName === "undefined"
              ? t("initiatives.form.edit.text")
              : t("initiatives.form.new.text")}
          </Typography>
          <TextField
            variant="outlined"
            value={text}
            onChange={(e) => setText(e.target.value.replace("=", " "))}
            label={t("initiatives.form.textAreaLabel")}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 280 }}
          />
          <div className={classes.textAreaCounter}>
            <Typography
              className={textAreaCounterClass}
              variant="overline"
            >{`${text.length}/280`}</Typography>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Fields;
