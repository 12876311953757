import React, { FC, memo } from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RouteEnum } from "model/enums/RouteEnum";
import ChallengeMenu from "./Menu";
import ChallengeStatements from "./Statements";
import ChallengeDone from "./Done";
import ChallengeRating from "./Rating";
import ChallengeResults from "./Results";
import ChallengeTraining from "./Training";
import ChallengeProgressPersonal from "./Progress/personal";
import ChallengeProgressTeam from "./Progress/team";

const Challenge: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route path={RouteEnum.CHALLENGE_PROGRESS_PERSONAL}>
        <ChallengeProgressPersonal />
      </Route>
      <Route path={RouteEnum.CHALLENGE_PROGRESS_TEAM}>
        <ChallengeProgressTeam />
      </Route>
      <Route path={RouteEnum.CHALLENGE_TRAINING}>
        <ChallengeTraining />
      </Route>
      <Route path={RouteEnum.REVIEW_DONE}>
        <ChallengeDone
          header={t("challenge.done.results.title")}
          text={t("challenge.done.results.subtitle")}
        />
      </Route>
      {/* <Route path={RouteEnum.CHALLENGE_STATEMENTS_REVIEW}>
        <ChallengeReview />
      </Route> */}
      <Route path={RouteEnum.CHALLENGE_DONE}>
        <ChallengeDone
          header={t("challenge.done.practical.title")}
          text={t("challenge.done.practical.subtitle")}
        />
      </Route>
      <Route path={RouteEnum.CHALLENGE_STATEMENTS} exact>
        <ChallengeStatements />
      </Route>
      <Route path={RouteEnum.CHALLENGE_RATING} exact>
        <ChallengeRating />
      </Route>
      <Route path={RouteEnum.CHALLENGE_RESULTS} exact>
        <ChallengeResults />
      </Route>
      <Route path={RouteEnum.CHALLENGE_RESULTS_DONE} exact>
        <ChallengeDone
          header={t("challenge.done.results.title")}
          text={t("challenge.done.results.subtitle")}
        />
      </Route>
      <Route path={RouteEnum.CHALLENGE} exact>
        <ChallengeMenu />
      </Route>
    </Switch>
  );
});

export default Challenge;
