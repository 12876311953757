import React, { FC, memo, useRef, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "../../model/enums/RouteEnum";

export const Menu: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);
  const handleClose = () => setIsOpen(false);

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={toggleMenu}
        edge="end"
        color="inherit"
        aria-label="menu"
        ref={buttonRef}
      >
        <MenuIcon />
      </IconButton>
      <Popover
        id="simple-menu"
        anchorPosition={{ top: 55, left: 55 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={buttonRef.current}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(RouteEnum.TUTORIAL)}>
          {t("menu.tutorial")}
        </MenuItem>
        <MenuItem onClick={() => history.push(RouteEnum.ACCESSIBILITY)}>
          {t("menu.accessibility")}
        </MenuItem>
      </Popover>
    </div>
  );
});
