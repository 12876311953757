import React from "react";
import { IInitiativeStatementDetailProps } from "pages/Initiatives/initiative";
import { Grid, Typography } from "@material-ui/core";
import Card from "components/Card";
import CommentsList from "pages/Initiatives/Detail/CommentsList";

const InitiativeStatementDetail: React.FC<IInitiativeStatementDetailProps> = ({
  initiativeStatement,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <Typography variant="subtitle1">Výrok: </Typography>
          <Typography variant="subtitle2">
            {initiativeStatement.statement.subject}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CommentsList comments={initiativeStatement.comments} />
      </Grid>
    </Grid>
  );
};

export default InitiativeStatementDetail;
