import React from "react";
import { Grid } from "@material-ui/core";
import Card from "components/Card";

export const TrainingCard: React.FC = ({ children }) => {
  return (
    <Card>
      <Grid container direction="column" spacing={2}>
        {children}
      </Grid>
    </Card>
  );
};
