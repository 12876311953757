import { Route, useHistory } from "react-router-dom";
import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { RouteEnum } from "../../model/enums/RouteEnum";
import { RolesEnum } from "../../model/enums/RolesEnum";

interface IProps {
  children: any;
  path: RouteEnum;
}

const AdminRoute: React.VFC<IProps> = memo(({ children, path }) => {
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`/auth/user`)
      .then((response) => {
        if (
          !response.data.roles.includes(RolesEnum.Admin) &&
          !response.data.roles.includes(RolesEnum.Auditor)
        ) {
          history.push(RouteEnum.HOMEPAGE);
        } else if (
          response.data.roles.includes(RolesEnum.Auditor) &&
          !response.data.roles.includes(RolesEnum.Admin)
        ) {
          history.push(RouteEnum.ADMIN_AUDIT);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [history]);

  return <Route path={path}>{children}</Route>;
});

export default AdminRoute;
