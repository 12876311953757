import { createMuiTheme, ThemeOptions } from "@material-ui/core";

const lightTheme: ThemeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#1A67D2",
      dark: "#00385F",
      light: "#64B5F6",
    },
    secondary: {
      main: "#E30613",
    },
    text: {
      primary: "#00385F",
    },
    info: {
      main: "#bbdefb",
      light: "#E7F3FD",
      dark: "#0078B4",
    },
    success: {
      main: "#339933",
    },
    error: {
      main: "#E30613",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontSize: 16,

    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),

    allVariants: {
      color: "#00385F",
    },

    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: "normal",
      fontStyle: "italic",
    },

    body1: {
      fontSize: 16,
    },
  },
};

export const theme = createMuiTheme({ ...lightTheme });

export const darkTheme = createMuiTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    type: "dark",
    text: {
      primary: "#fff",
    },
    background: {
      default: "#000",
    },
    info: {
      ...lightTheme.palette?.info,
      main: "#262525FF",
      light: "#262525FF",
    },
  },
  typography: {
    ...lightTheme.typography,
    allVariants: { color: "#fff" },
  },
});
