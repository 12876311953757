import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AppBarLayout, Layout } from "../../../components";
import { useApi } from "../../../model/hooks/useApi";
import { ApiUrlEnum } from "../../../model/enums/ApiUrlEnum";
import ProgressContainer from "../../../components/Training/ProgressConteiner";
import Header from "../Header";
import AchievementImage from "../../../assets/images/achievement.svg";

const levels = [
  {
    title: "Stupeň I",
    description:
      "Možná pro vás v týmu bylo FHC DNA novinkou, ale není to vůbec poznat. Jen tak dál, trénink dělá mistra!",
    image: AchievementImage,
    points: 120,
  },
  {
    title: "Stupeň II",
    description:
      "FHC DNA nasáváte jako houby a nebojíte se výroky zkoušet v praxi. Brzy budete v týmu samí ostřílení mazáci!",
    image: AchievementImage,
    points: 270,
  },
  {
    title: "Stupeň III",
    description:
      "FHC DNA důvěrně znáte a málokdy váš tým něco překvapí. Nepolevujte, k mistrovství už vám chybí jen kousek!",
    image: AchievementImage,
    points: 400,
  },
  {
    title: "Stupeň IV",
    description:
      "Žádný výrok pro vás není problém a i Chuck Norris si kvůli FHC DNA chodí za vaším týmem pro radu. Gratulace!",
    image: AchievementImage,
    points: 550,
  },
];

const ChallengeProgressTeam: React.VFC = () => {
  const { t } = useTranslation();
  const api = useApi();

  const [points, setPoints] = useState<number>(0);

  React.useEffect(() => {
    api
      .get<number>(ApiUrlEnum.TEAM_POINTS)
      .then((response) => {
        if (response.data) {
          return setPoints(response.data);
        }
        return setPoints(0);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [api]);

  return (
    <AppBarLayout>
      <Layout
        top={
          <>
            <Header
              leftText={t("challenge.progress.team")}
              rightText={`(${points} bodů)`}
            />
            <Grid container style={{ marginTop: 30 }}>
              <Grid item>
                <ProgressContainer levels={levels} points={points} />
              </Grid>
            </Grid>
          </>
        }
      />
    </AppBarLayout>
  );
};

export default ChallengeProgressTeam;
