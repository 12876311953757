import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ButtonsList } from "../../../components";

const Exports: FC = memo(() => {
  const { t } = useTranslation();

  const redirectToExport = (kind: string) => {
    window.location.href = `${process.env.REACT_APP_API_PREFIX}/${kind}/export`;
    return false;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom component="h5">
              {t("admin.buttons.exports")}
            </Typography>
            <ButtonsList
              buttons={[
                {
                  key: 1,
                  onClick: () => redirectToExport("statements"),
                  variant: "contained",
                  color: "primary",
                  text: t("admin.exports.buttons.statements"),
                },
                {
                  key: 2,
                  onClick: () => redirectToExport("questions"),
                  variant: "contained",
                  color: "primary",
                  text: t("admin.exports.buttons.questions"),
                },
                {
                  key: 3,
                  onClick: () => redirectToExport("users"),
                  variant: "contained",
                  color: "primary",
                  text: t("admin.exports.buttons.users"),
                },
                {
                  key: 3,
                  onClick: () => redirectToExport("squads"),
                  variant: "contained",
                  color: "primary",
                  text: t("admin.exports.buttons.squads"),
                },
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default Exports;
