import React, { FC, memo, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { RouteEnum } from "model/enums/RouteEnum";
import { Overview } from "./Overview";
import { NewInitiative } from "./NewInitiative";
import { Detail } from "./Detail";
import { Comment } from "./Comment";
import { TeamsContext } from "../../model/contexts/TeamsContext";
import { Teams } from "./Teams";
import { ITeam } from "./initiative";

const Initiatives: FC = memo(() => {
  const [team, setTeam] = useState<ITeam>();
  return (
    <TeamsContext.Provider value={{ team, setTeam }}>
      <Switch>
        <Route path={RouteEnum.INITIATIVES_TEAMS}>
          <Teams />
        </Route>
        <Route path={RouteEnum.INITIATIVES_NEW}>
          <NewInitiative />
        </Route>
        <Route path={RouteEnum.INITIATIVES_DETAIL}>
          <Detail />
        </Route>
        <Route path={RouteEnum.INITIATIVES_COMMENT}>
          <Comment />
        </Route>
        <Route path="/">
          <Overview />
        </Route>
      </Switch>
    </TeamsContext.Provider>
  );
});

export default Initiatives;
