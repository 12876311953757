import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
    margin: "30px auto",
    width: 220,
    height: 220,
  },
  circle: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  circleFull: {
    color: theme.palette.success.main,
  },
  circleErrors: {
    color: theme.palette.error.main,
  },
}));

const ProgressCircle: React.VFC<{
  errorsPercentage: number;
}> = ({ errorsPercentage }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box position="absolute" top="90px" left={0} right={0} textAlign="center">
        <Typography variant="h5">Dobrá práce</Typography>
      </Box>
      <CircularProgress
        className={clsx(classes.circle, classes.circleFull)}
        color="secondary"
        size={220}
        variant="determinate"
        value={100}
      />
      <CircularProgress
        className={clsx(classes.circle, classes.circleErrors)}
        color="primary"
        size={220}
        variant="determinate"
        value={errorsPercentage}
      />
    </Box>
  );
};

export default ProgressCircle;
