import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppBarLayout, Button, Layout } from "components";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { RouteEnum } from "model/enums/RouteEnum";
import { useApi } from "model/hooks/useApi";
import { IAnswerEntity } from "model/interfaces/api-entity";
import Header from "pages/Challenge/Header";
import StatementRatingCard from "./StatementRatingCard";

const Rating: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const api = useApi();
  const [answer, setAnswer] = React.useState<IAnswerEntity | undefined>(
    undefined
  );
  const [rating, setRating] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchQueue = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get<IAnswerEntity[]>(ApiUrlEnum.ANSWERS_QUEUE);
      if (response.data.length > 0) {
        setIsLoading(false);
        setRating(null);
        return setAnswer(response.data[0]);
      }
      return history.push(RouteEnum.REVIEW_DONE);
    } catch (e) {
      console.log(e);
      return history.push(RouteEnum.CHALLENGE);
    }
  }, [api, history]);

  React.useEffect(() => {
    fetchQueue();
  }, [fetchQueue]);

  const submit = async () => {
    if (!rating || !answer || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await api.post(ApiUrlEnum.REVIEWS, {
        answer: answer._id,
        rating,
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
    await fetchQueue();
  };

  return (
    <AppBarLayout>
      <Layout top={<Header leftText={t("challenge.rating.header")} />}>
        {!isLoading && answer && (
          <StatementRatingCard answer={answer} onRating={setRating} />
        )}
        <Button
          text={t("challenge.rating.rate")}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          disabled={isLoading || !rating || !answer}
          onClick={submit}
        />
      </Layout>
    </AppBarLayout>
  );
};

export default Rating;
