import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface IAnswer {
  [key: string]: string;
}

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
  },
  optionBox: {
    display: "inline-block",
    height: "45px",
    width: "45px",
    fontSize: 30,
    lineHeight: "45px",
    borderRadius: "4px",
  },
  optionText: {
    paddingLeft: "0.8rem",
    margin: "auto",
  },
});

export const Answer: React.VFC<{
  id: string;
  text: string;
  selected: boolean;
  onSelect: () => void;
  isCorrect: boolean;
  answered?: string;
  wrongAnswers?: IWrongAnswer[];
  questionId: string;
}> = ({
  onSelect,
  selected,
  text,
  id,
  answered,
  isCorrect,
  wrongAnswers,
  questionId,
}) => {
  const classes = useStyles();

  const color = React.useMemo(() => {
    if (answered && answered === id) {
      return isCorrect ? "success.main" : "error.main";
    }

    const mistakes = wrongAnswers?.filter(
      (a) => a.questionId === questionId && a.answerId === id
    );

    if (mistakes && mistakes.length > 0) {
      return "error.main";
    }

    return selected ? "primary.dark" : "primary.light";
  }, [selected, answered, isCorrect, id, wrongAnswers, questionId]);

  return (
    <Grid
      container
      style={{ paddingBottom: 5, paddingTop: 5 }}
      onClick={onSelect}
      className={classes.root}
    >
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <Box
          className={classes.optionBox}
          bgcolor={color}
          color="common.white"
          textAlign="center"
        >
          {id}
        </Box>
      </Grid>
      <Grid item xs={10} className={classes.optionText}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Grid>
  );
};
