import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ProgressBox from "components/Training/ProgressBox";
import PersonalProgressImage from "assets/images/person.svg";
import TeamProgressImage from "assets/images/team.svg";
import { AppBarLayout, ButtonsList, IButton, Layout } from "components";
import { RouteEnum } from "model/enums/RouteEnum";
import { useHistory } from "react-router-dom";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { makeStyles } from "@material-ui/core/styles";
import { IChallengeStreakProps, IQueueStatuses } from "../challenge";

const useStyles = makeStyles({
  streakLabel: {
    display: "inline-block",
    fontWeight: "bold",
    fontSize: "16px",
  },
  streakPostfix: {
    display: "inline-block",
    fontSize: "16px",
  },
});

const ChallengeStreak: React.FC<IChallengeStreakProps> = ({ streak }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.streakLabel}>
          {streak} {streak === 1 && t("challenge.streak.day")}
          {streak > 1 && streak < 5 && t("challenge.streak.day2")}
          {(streak < 1 || streak > 4) && t("challenge.streak.day5")}
        </Typography>{" "}
        <Typography className={classes.streakPostfix}>
          {t("challenge.streak.row")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ChallengeMenu: React.FC = () => {
  const api = useApi();
  const [queueStatuses, setQueueStatuses] = useState({
    statements: 0,
    reviews: 0,
    trainings: 0,
    streak: 0,
  } as IQueueStatuses);
  const { t } = useTranslation();
  const history = useHistory();

  const buttons: IButton[] = [
    {
      key: 1,
      onClick: () => history.push(RouteEnum.CHALLENGE_TRAINING),
      variant: "contained",
      color: "primary",
      text: t("challenge.buttons.training"),
      badge: queueStatuses?.trainings,
      disabled: !(queueStatuses?.trainings && queueStatuses?.trainings > 0),
    },
    {
      key: 2,
      onClick: () => history.push(RouteEnum.CHALLENGE_STATEMENTS),
      variant: "contained",
      color: "primary",
      text: t("challenge.buttons.practical"),
      badge: queueStatuses?.statements,
      disabled: !(queueStatuses?.statements && queueStatuses?.statements > 0),
    },
  ];

  if (process.env.REACT_APP_FEATURE_SWITCH_TEAMS === "true") {
    buttons.push({
      key: 3,
      onClick: () => history.push(RouteEnum.CHALLENGE_RATING),
      variant: "contained",
      color: "primary",
      text: t("challenge.buttons.ratings"),
      badge: queueStatuses?.reviews,
      disabled: !(queueStatuses?.reviews && queueStatuses?.reviews > 0),
    });

    buttons.push({
      key: 4,
      onClick: () => history.push(RouteEnum.CHALLENGE_RESULTS),
      variant: "contained",
      color: "primary",
      text: t("challenge.buttons.results"),
    });
  }

  useEffect(() => {
    const fetchUserQueueStatuses = async () => {
      try {
        const response = await api.get(ApiUrlEnum.USERS_QUEUE_STATUSES);
        if (response.data) {
          const { statements, reviews, trainings, streak } = response.data;
          setQueueStatuses({
            statements,
            reviews,
            trainings,
            streak,
          });
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    };

    fetchUserQueueStatuses();
    const interval = setInterval(() => fetchUserQueueStatuses(), 30000);
    // destroy interval on unmount
    return () => clearInterval(interval);
  }, [api]);

  return (
    <AppBarLayout>
      <Layout top={<ChallengeStreak streak={queueStatuses?.streak} />}>
        <Grid container spacing={3} style={{ paddingBottom: 10 }}>
          <Grid item xs={12}>
            <ProgressBox
              image={PersonalProgressImage}
              title={t("challenge.progress.personal")}
              onClick={() => {
                history.push(RouteEnum.CHALLENGE_PROGRESS_PERSONAL);
              }}
            />
          </Grid>
          {process.env.REACT_APP_FEATURE_SWITCH_TEAMS === "true" && (
            <Grid item xs={6}>
              <ProgressBox
                image={TeamProgressImage}
                title={t("challenge.progress.team")}
                onClick={() => {
                  history.push(RouteEnum.CHALLENGE_PROGRESS_TEAM);
                }}
              />
            </Grid>
          )}
        </Grid>
        <ButtonsList buttons={buttons} />
      </Layout>
    </AppBarLayout>
  );
};

export default ChallengeMenu;
