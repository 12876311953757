import React from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import { AppBarLayout, Layout } from "components";
import { ThemeToggleContext } from "model/contexts/ThemeToggleContext";
import { useTranslation } from "react-i18next";

const Accessibility: React.FC = () => {
  const { t } = useTranslation();
  const themeToggle = React.useContext(ThemeToggleContext);

  return (
    <AppBarLayout>
      <Layout
        top={
          <>
            <Typography variant="h6" style={{ marginBottom: 20 }}>
              {t("menu.accessibility")}
            </Typography>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={themeToggle.isDark}
                      onChange={themeToggle.toggle}
                      name="theme-switcher"
                    />
                  }
                  label={t("accessibility.dark")}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </AppBarLayout>
  );
};

export default Accessibility;
