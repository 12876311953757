import React, { FC, memo, useEffect } from "react";
import { AppBarLayout, ButtonsList, Logo, Layout, IButton } from "components";
import { RouteEnum } from "model/enums/RouteEnum";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { UserContext } from "../../model/contexts/UserContext";

const Homepage: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = React.useContext(UserContext);

  useEffect(() => {
    const cookies = new Cookies();
    if (
      cookies.get(process.env.REACT_APP_TUTORIAL_COOKIE ?? "tutorialPassed") !==
      "1"
    ) {
      history.push(RouteEnum.TUTORIAL);
    }
  }, [history]);

  const buttons: IButton[] = [];

  if (user?.allowedChallenge) {
    buttons.push({
      key: 10,
      onClick: () => history.push(RouteEnum.CHALLENGE),
      variant: "contained",
      color: "primary",
      text: t("homepage.buttons.challenge"),
    });
  } else {
    buttons.push({
      key: 20,
      onClick: () => history.push(RouteEnum.FREE_TRAININGS),
      variant: "contained",
      color: "primary",
      text: t("homepage.buttons.freeTrainings"),
    });
  }

  if (process.env.REACT_APP_FEATURE_SWITCH_INITIATIVES === "true") {
    buttons.push({
      key: 30,
      onClick: () => history.push(RouteEnum.INITIATIVES),
      variant: "contained",
      color: "primary",
      text: t("homepage.buttons.initiative"),
    });
  }

  if (process.env.REACT_APP_FEATURE_SWITCH_STATEMENTS === "true") {
    buttons.push({
      key: 40,
      onClick: () => history.push(RouteEnum.STATEMENTS),
      variant: "contained",
      color: "primary",
      text: t("homepage.buttons.statements"),
    });
  }

  return (
    <AppBarLayout showMenu>
      <Layout top={<Logo />}>
        <ButtonsList buttons={buttons} />
      </Layout>
    </AppBarLayout>
  );
});

export default Homepage;
