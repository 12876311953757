import React from "react";
import { AppBarLayout, Button, Layout } from "components";
import Header from "pages/Challenge/Header";
import { IResultsEntity } from "model/interfaces/api-entity";
import Stats from "pages/Challenge/Results/Stats";
import ResultStatementCard from "pages/Challenge/Results/ResultStatementCard";
import RatedGroup from "pages/Challenge/Results/RatedGroup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Paper, Typography } from "@material-ui/core";
import { RouteEnum } from "../../../model/enums/RouteEnum";

const Results: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [statement, setStatement] = React.useState<number>(0);
  const [results, setResults] = React.useState<IResultsEntity[] | undefined>(
    undefined
  );

  React.useEffect(() => {
    setIsLoading(true);
    const fetchResults = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}/reviews/results`
        );
        setResults(response.data);
        setStatement(0);
        setIsLoading(false);
        return true;
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
      return false;
    };

    fetchResults();
  }, []);

  const handleNext = () => {
    if (!!results && results.length > 0) {
      setStatement((prevStatement) =>
        prevStatement + 1 < results.length ? prevStatement + 1 : 0
      );
    }
    return false;
  };

  const handlePrev = () => {
    if (!!results && results.length > 0) {
      setStatement((prevStatement) =>
        prevStatement - 1 >= 0 ? prevStatement - 1 : results.length - 1
      );
    }
    return false;
  };

  return (
    <AppBarLayout>
      <Layout top={<Header leftText={t("challenge.results.title")} />}>
        {!isLoading && !!results && results.length > 0 && (
          <>
            <Stats results={results[statement]} />
            <ResultStatementCard results={results[statement]} />
            <RatedGroup results={results[statement]} />
          </>
        )}
        {!isLoading && !!results && results.length < 1 && (
          <>
            <Paper style={{ padding: 20 }}>
              <Typography variant="body2">
                V tuto chvíli nejsou dostupné žádné výsledky. Čeká se na
                hodnocení týmovými kolegy. Zkuste to později.
              </Typography>
            </Paper>
            <Button
              text={t("challenge.training.backToHomepage")}
              variant="contained"
              color="primary"
              onClick={() => history.push(RouteEnum.CHALLENGE)}
            />
          </>
        )}
        {!!results && statement < results.length - 1 && (
          <Button
            text={t("challenge.results.nextButton")}
            variant="contained"
            color="primary"
            isLoading={isLoading}
            onClick={handleNext}
          />
        )}
        {statement > 0 && (
          <Button
            text={t("challenge.results.prevButton")}
            variant="contained"
            color="default"
            isLoading={isLoading}
            onClick={handlePrev}
          />
        )}
      </Layout>
    </AppBarLayout>
  );
};

export default Results;
