import React, { FC, memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBarLayout, Layout } from "components";
import { Header } from "pages/Statements/List/Header";
import { List } from "./List";
import { getAllStatements, sortStatements } from "../services";
import {
  IStatement,
  IStatementCategory,
  IStatementsListProps,
} from "../statement";

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    marginTop: theme.spacing(2),
  },
}));

export const StatementsList: FC<IStatementsListProps> = memo(
  ({ group, category, allStatements, goBack }) => {
    const classes = useStyles();
    const [title, setTitle] = useState("All");
    const [list, setList] = useState([] as IStatement[]);

    useEffect(() => {
      const categoryData: IStatementCategory | undefined =
        category && group
          ? allStatements[group].categories[category]
          : undefined;

      const all: IStatement[] = getAllStatements(allStatements);

      setTitle(categoryData?.title || "All");
      setList(sortStatements(categoryData?.list || all));
    }, [category, group, allStatements]);

    return (
      <AppBarLayout showMenu>
        <Layout top={<Header title={title} goBack={goBack} />}>
          <div className={classes.listWrapper}>
            <List data={list} />
          </div>
        </Layout>
      </AppBarLayout>
    );
  }
);
