import React, { FC, memo, useCallback, useState } from "react";
import { AppBarLayout, Button, Layout, SearchField } from "components";
import { useTranslation } from "react-i18next";
import { StatementsGroup } from "./StatementsGroup";
import { Description } from "./Description";
import { Search } from "../Search";
import { IStatementsOverviewProps, StatementGroup } from "../statement";
import { StatementsSectionEnum } from "../StatementsSectionEnum";

export const Overview: FC<IStatementsOverviewProps> = memo(
  ({ setCategory, setSection, data, allStatements, groups }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");

    const getGroup = useCallback((id: StatementGroup) => data[id], [data]);

    const onSearch = (term: string) => setSearchTerm(term);

    return (
      <AppBarLayout showMenu>
        <Layout
          top={
            <>
              <Description />
              <SearchField onSearch={onSearch} />
              <Search term={searchTerm} allStatements={allStatements} />
            </>
          }
        >
          {searchTerm === "" && (
            <>
              <Button
                text={t("statements.buttons.all")}
                onClick={() => setSection(StatementsSectionEnum.ALL)}
                variant="contained"
                color="primary"
              />
              {groups?.map((group: { id: StatementGroup }) => (
                <StatementsGroup
                  key={group.id}
                  id={group.id}
                  getGroup={getGroup}
                  setCategoryGroup={setCategory}
                />
              ))}
            </>
          )}
        </Layout>
      </AppBarLayout>
    );
  }
);
