import React from "react";
import { IResultRatedGroupItemProps } from "pages/Challenge/challenge";
import { ListItem, ListItemText, Typography } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  usersAnswerItem: {
    backgroundColor: blue[100],
  },
});

const RatedGroupItem: React.FC<IResultRatedGroupItemProps> = ({
  answerText,
  answerIndex,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      alignItems="flex-start"
      selected={answerIndex % 2 !== 0}
      /* className={answer.isUsers ? classes.usersAnswerItem : undefined} */
    >
      <ListItemText>
        <Typography variant="subtitle2">&quot;{answerText}&quot;</Typography>
      </ListItemText>
    </ListItem>
  );
};

export default RatedGroupItem;
