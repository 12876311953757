import React from "react";
import { Grid } from "@material-ui/core";
import { Button } from "components";
import { makeStyles } from "@material-ui/core/styles";
import { IInitiativeDetailActionButtonProps } from "pages/Initiatives/initiative";
import { InitiativeStatusEnum } from "pages/Initiatives/Detail/InitiativeStatusEnum";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    backgroundColor: theme.palette.info.light,
    boxShadow: "none",
    color: theme.palette.info.dark,
  },
}));

const ActionButtons: React.FC<IInitiativeDetailActionButtonProps> = ({
  setDialogType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          onClick={() => setDialogType(InitiativeStatusEnum.ARCHIVED)}
          className={classes.actionButtons}
          text={t("initiatives.detail.actionButtons.cancel")}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={() => setDialogType(InitiativeStatusEnum.DONE)}
          className={classes.actionButtons}
          text={t("initiatives.detail.actionButtons.close")}
        />
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
