import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, List } from "@material-ui/core";
import Card from "components/Card";
import StatementsListItem from "pages/Initiatives/Detail/StatementsListItem";
import { IStatementListProps } from "pages/Initiatives/initiative";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StatementsList: React.FC<IStatementListProps> = ({
  initiativeComments,
  onSelected,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <List className={classes.list}>
        {initiativeComments.map((item, itemIndex) => (
          <React.Fragment key={item.statement._id}>
            <StatementsListItem
              item={item}
              index={itemIndex}
              onClick={() => onSelected(item)}
            />
            {itemIndex !== initiativeComments.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
};

export default StatementsList;
