import React, { FC, memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { UserContext } from "../../../model/contexts/UserContext";
import { RouteEnum } from "../../../model/enums/RouteEnum";
import { ButtonsList } from "../../../components";
import { TeamsContext } from "../../../model/contexts/TeamsContext";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
  button: {
    justifyContent: "space-between",
    textTransform: "none",
  },
  icon: {
    fontSize: "1rem",
  },
}));

export const Description: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const user = useContext(UserContext);
  const team = useContext(TeamsContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        {user?.otherSquads ? (
          <ButtonsList
            buttons={[
              {
                key: 1,
                onClick: () => history.push(RouteEnum.INITIATIVES_TEAMS),
                variant: "outlined",
                text: (
                  <>
                    <Typography>
                      {team.team?.title ? team.team.title : user.squad.title}
                    </Typography>
                    <ArrowForwardIosIcon
                      className={classes.icon}
                      color="inherit"
                    />
                  </>
                ),
                className: classes.button,
              },
            ]}
          />
        ) : (
          <Typography className={classes.description}>
            {t("initiatives.description")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
});
