import People from "../../assets/images/people.svg";
import Helping from "../../assets/images/helping.svg";
import Couple from "../../assets/images/couple.svg";

const carouselItems = [
  {
    key: 1,
    image: People,
    name: "Principy zákaznické zkušenosti",
    description:
      "Firmy s dobrou CX strategií mají stanovené základní principy, podle kterých designují nové věci a upravují existující tak, aby jejich klienti měli dohodnutý a jednotný zážitek.",
  },
  {
    key: 2,
    image: Helping,
    name: "Jak to máme v ČS",
    description:
      "I my ve Spořce máme 3 DNA principy - Vidíme to vašima očima, Být s námi se vám vyplácí a Já to s vámi vyřeším. K nim máme sadu konkrétních praktických výroků, které říkají, co to konkrétně znamená, když tvořím interakce v Georgi, vymýšlím novou propozici nebo když komunikuji s klientem.",
  },
  {
    key: 3,
    image: Couple,
    name: "Týmová práce je klíčem k úspěchu",
    description:
      "V aplikaci najdete přehledně zpracované DNA prinicpy, které můžete v rámci celého týmu používat při konkrétních tématech, zejména při Discovery. Zároveň budete mít vždy po ruce možnost zkoušet si, jak jste na tom prakticky se znalostí jednotlivých principů.",
  },
];

export default carouselItems;
