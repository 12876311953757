import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { ButtonsList } from "../../../components";

const Audits: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom component="h5">
              {t("admin.buttons.audit")}
            </Typography>
            <ButtonsList
              buttons={[
                {
                  key: 1,
                  onClick: () => {
                    window.location.href = `${process.env.REACT_APP_API_PREFIX}/users/export-access-log`;
                    return false;
                  },
                  variant: "contained",
                  color: "primary",
                  text: t("admin.exports.buttons.audit"),
                },
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});

export default Audits;
