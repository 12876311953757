import React, { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

export const SearchField: FC<{ onSearch: (term: string) => void }> = memo(
  ({ onSearch }) => {
    const { t } = useTranslation();

    const [term, setTerm] = useState("");

    const onChange = (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      const { value } = e.target;
      setTerm(value);
      onSearch(value);
    };

    return (
      <TextField
        onChange={onChange}
        value={term}
        placeholder={t("Search")}
        variant="outlined"
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined color="disabled" />
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
