import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IHeaderProps } from "pages/Challenge/challenge";
import { Link as RouterLink } from "react-router-dom";
import { RouteEnum } from "../../model/enums/RouteEnum";
import BackButton from "../../components/BackButton";

const useStyles = makeStyles({
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
});

const Header: React.FC<IHeaderProps> = ({ leftText, rightText }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={8} className={classes.textLeft}>
        <Typography variant="subtitle1">
          {leftText && `${leftText} `}
          {rightText && rightText}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.textRight}>
        <Link
          variant="body1"
          component={RouterLink}
          to={RouteEnum.CHALLENGE}
          style={{ position: "relative", top: -8 }}
        >
          <BackButton />
        </Link>
      </Grid>
    </Grid>
  );
};

export default Header;
