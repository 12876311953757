import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const Dashboard: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Typography variant="h5" gutterBottom component="h5">
      {t("admin.title")}
    </Typography>
  );
});

export default Dashboard;
