import React, { FC, memo } from "react";
import { Card, Divider, Grid, List as MaterialList } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IStatement } from "pages/Statements/statement";
import { Item } from "./Item";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const List: FC<{ data: undefined | IStatement[] }> = memo(({ data }) => {
  const classes = useStyles();

  if (!data) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <MaterialList className={classes.root}>
            {data.map((statement, index) => (
              <>
                <Item key={statement.id} index={index + 1} {...statement} />
                {index !== data.length - 1 && <Divider />}
              </>
            ))}
          </MaterialList>
        </Card>
      </Grid>
    </Grid>
  );
});
