import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  box: {
    borderRadius: "4px",
    boxShadow: "0px 5px 10px #0000001A",
  },
  ilu: {
    maxWidth: "100%",
  },
});

interface IProgressBox {
  title: string;
  image: string;
  onClick: () => void;
}

const ProgressBox: React.VFC<IProgressBox> = ({ onClick, title, image }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.box}
      bgcolor="common.white"
      width="100%"
      overflow="hidden"
      boxShadow={0}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ padding: 10 }}
      >
        <Grid item>
          <img src={image} alt={title} className={classes.ilu} />
        </Grid>
        <Grid item>
          <Typography variant="body2" color="primary">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgressBox;
