import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StatementCard } from "components";
import { IReviewProps } from "../challenge";

const Review: React.FC<IReviewProps> = ({ text, statement }) => {
  const { t } = useTranslation();

  return (
    <StatementCard>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          &quot;{statement?.subject}&quot;
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {t("challenge.statements.comment")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </StatementCard>
  );
};

export default Review;
