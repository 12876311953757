import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApi } from "model/hooks/useApi";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import { UserContext } from "model/contexts/UserContext";
import FullPageLoader from "components/FullPageLoader";
import { AppBarLayout, Layout } from "components";
import {
  IInitiativeEntity,
  IInitiativeResponseEntity,
  IInitiativeStatementGroup,
} from "pages/Initiatives/initiative";
import Stats from "./Stats";
import Actions from "./Actions";
import StatementsList from "./StatementsList";
import { getCommentsGroupedByStatement } from "./helpers";
import InitiativeStatementDetail from "./InitiativeStatementDetail";
import Header from "./Header";
import { InitiativeStatusEnum } from "./InitiativeStatusEnum";
import { RouteEnum } from "../../../model/enums/RouteEnum";

export const Detail: React.FC = () => {
  const history = useHistory();
  const api = useApi();
  const user = useContext(UserContext);
  const { id } = useParams() as { id: string };
  const [commented, setCommented] = React.useState<number>(0);
  const [waiting, setWaiting] = React.useState<number>(0);
  const [initiative, setInitiative] = React.useState<IInitiativeEntity | null>(
    null
  );
  const [initiativeComments, setGroupedComments] = React.useState<
    IInitiativeStatementGroup[]
  >([]);
  const [selectedInitiativeStatement, setSelectedInitiativeStatement] =
    React.useState<IInitiativeStatementGroup | undefined>();

  React.useEffect(() => {
    api
      .get<IInitiativeResponseEntity>(`${ApiUrlEnum.INITIATIVES_DETAIL}/${id}`)
      .then((response) => {
        const {
          data: { initiativeDetail, totalCommented, totalWaiting },
        } = response;
        setInitiative(initiativeDetail);
        setGroupedComments(getCommentsGroupedByStatement(initiativeDetail));
        setWaiting(totalWaiting);
        setCommented(totalCommented);
      })
      .catch((err) => console.error(err));
  }, [id, api]);

  const userId = user?._id;
  const authorId = initiative?.author;

  const isMineInitiative = React.useMemo(
    () => userId === authorId,
    [userId, authorId]
  );

  if (!initiative) {
    return <FullPageLoader />;
  }

  return (
    <AppBarLayout>
      <Layout
        top={
          <Header
            initiative={initiative}
            goBack={
              selectedInitiativeStatement
                ? () => setSelectedInitiativeStatement(undefined)
                : () => history.push(RouteEnum.INITIATIVES)
            }
          />
        }
      >
        {!selectedInitiativeStatement ? (
          <>
            <Stats commented={commented} waiting={waiting} />
            {isMineInitiative &&
              ![
                InitiativeStatusEnum.ARCHIVED,
                InitiativeStatusEnum.DONE,
              ].includes(initiative.status) && (
                <Actions initiative={initiative} />
              )}
            <StatementsList
              initiativeComments={initiativeComments}
              onSelected={setSelectedInitiativeStatement}
            />
          </>
        ) : (
          <InitiativeStatementDetail
            initiativeStatement={selectedInitiativeStatement}
          />
        )}
      </Layout>
    </AppBarLayout>
  );
};
