import {
  IStatement,
  IStatementCategory,
  IStatementsData,
} from "pages/Statements/statement";

export const sortStatements = (data: IStatement[]): IStatement[] => {
  return data.sort((a: IStatement, b: IStatement) => {
    const orderA = a.ordering ?? 0;
    const orderB = b.ordering ?? 0;

    if (orderA > orderB) return 1;
    if (orderA === orderB) return 0;
    return -1;
  });
};

export const getAllStatements = (data: IStatementsData): IStatement[] => {
  const all: IStatement[] = [];
  const uniquesStatementsId: number | undefined[] = [];
  Object.values(data.principles.categories).map((c: IStatementCategory) =>
    c.list.filter((s: any) => {
      if (!uniquesStatementsId.includes(s.id)) {
        uniquesStatementsId.push(s.id);
        all.push(s);
      }
      return true;
    })
  );

  return sortStatements(all);
};
