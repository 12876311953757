import React, { FC, memo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CXDnaLogo from "assets/images/cxdna-logo-placeholder.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    logoWrapper: {
      position: "relative",
      margin: theme.spacing(2),
      textAlign: "center",
    },
    logo: {
      display: "inline-block",
      height: "35vh",
      maxHeight: "300px",
    },
  })
);

export const Logo: FC = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.logoWrapper}>
      <img id="logo" className={classes.logo} alt="FHCDNA" src={CXDnaLogo} />
    </div>
  );
});
