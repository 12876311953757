import React from "react";
import { IStatementsModalProps } from "pages/Statements/statement";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { StatementsAsSelectContext } from "model/contexts/StatementsAsSelectContext";
import { HideAppBarContext } from "components/AppBar/HideAppBarContext";
import Statements from "pages/Statements";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatementsModal: React.FC<IStatementsModalProps> = ({
  isOpen,
  close,
  onSelected,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <StatementsAsSelectContext.Provider value={onSelected}>
        <HideAppBarContext.Provider value>
          <Statements />
        </HideAppBarContext.Provider>
      </StatementsAsSelectContext.Provider>
    </Dialog>
  );
};

export default StatementsModal;
