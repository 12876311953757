import React from "react";
import { darkTheme, theme as lightTheme } from "theme";
import { IThemeObject, IUseTheme } from "model/hooks/hook";

const LOCAL_STORAGE_KEY = "theme-preference";
const LOCAL_STORAGE_DARK = "dark";
const LOCAL_STORAGE_LIGHT = "light";

const lightThemeObject: IThemeObject = {
  theme: lightTheme,
  isDark: false,
};

const darkThemeObject: IThemeObject = {
  theme: darkTheme,
  isDark: true,
};

export const useTheme = (): IUseTheme => {
  const defaultTheme = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [theme, setTheme] = React.useState<IThemeObject>(
    defaultTheme === LOCAL_STORAGE_DARK ? darkThemeObject : lightThemeObject
  );
  const toggle = () => {
    const newThemeType = theme.isDark ? lightThemeObject : darkThemeObject;
    const newThemeObject = {
      ...newThemeType,
      theme: { ...newThemeType.theme },
    };
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      newThemeObject.isDark ? LOCAL_STORAGE_DARK : LOCAL_STORAGE_LIGHT
    );
    setTheme(newThemeObject);
  };

  return { theme, toggle };
};
