import React, { FC, memo, useContext, useState } from "react";
import { AppBarLayout, ButtonsList, Layout } from "components";
import { useHistory } from "react-router-dom";
import { useApi } from "model/hooks/useApi";
import { RouteEnum } from "model/enums/RouteEnum";
import { ApiUrlEnum } from "model/enums/ApiUrlEnum";
import Fields from "pages/Initiatives/Fields";
import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { IStatement } from "pages/Statements/statement";
import { TeamsContext } from "model/contexts/TeamsContext";

const useStyles = makeStyles((theme) => ({
  cancelButtonIcon: {
    marginRight: theme.spacing(1),
    fontSize: "1.5rem",
  },
  cancelButtonWrapper: {
    margin: "auto",
  },
}));

export const NewInitiative: FC = memo(() => {
  const [name, setName] = useState("");
  const [statement, setStatement] = useState<IStatement>();
  const [text, setText] = useState("");
  const { team } = useContext(TeamsContext);
  const classes = useStyles();
  const history = useHistory();
  const api = useApi();

  const createInitiative = async () => {
    try {
      await api.post(ApiUrlEnum.INITIATIVES, {
        name,
        statement: statement?.id,
        text,
        squad: typeof team !== "undefined" ? team._id : undefined,
      });
      history.push(RouteEnum.INITIATIVES);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AppBarLayout showMenu>
      <Layout
        top={<Typography variant="subtitle1">Nová iniciativa</Typography>}
      >
        <Fields
          name={name}
          setName={setName}
          statement={statement}
          setStatement={setStatement}
          text={text}
          setText={setText}
        />
        <ButtonsList
          buttons={[
            {
              key: 1,
              variant: "contained",
              color: "primary",
              text: "Vytvořit Iniciativu",
              disabled: !statement || !name || !text,
              onClick: createInitiative,
            },
            {
              onClick: () => history.push(RouteEnum.INITIATIVES),
              key: 1,
              variant: "contained",
              color: "secondary",
              text: (
                <>
                  <Delete className={classes.cancelButtonIcon} /> Zrušit
                </>
              ),
            },
          ]}
        />
      </Layout>
    </AppBarLayout>
  );
});
