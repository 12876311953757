import React, { FC, memo, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, List, ListItem, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { AppBarLayout, Layout, SearchField } from "components";
import { UserContext } from "model/contexts/UserContext";
import { RouteEnum } from "model/enums/RouteEnum";
import { TeamsContext } from "model/contexts/TeamsContext";
import { ITeam } from "../initiative";
import BackButton from "../../../components/BackButton";

const useStyles = makeStyles(() => ({
  listWrapper: {
    boxShadow: "0 0 6px rgba(0, 0, 0, 0.16)",
  },
  list: {
    padding: 0,
  },
  listItem: {
    justifyContent: "space-between",
    padding: "16px",
  },
  highlighted: {
    backgroundColor: "#bbdefb",
  },
  searchField: {
    marginBottom: "16px",
    marginTop: "16px",
  },
  icon: {
    fontSize: "1rem",
  },
}));

export const Teams: FC = memo(() => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const { team, setTeam } = useContext(TeamsContext);

  console.log("TEAM", team);

  const teams: ITeam[] =
    typeof user !== "undefined" && user.otherSquads !== null
      ? (user.otherSquads as unknown as ITeam[])
      : [];

  const selectTeam = (selectedTeam?: ITeam) => {
    setTeam(selectedTeam);
    history.push(RouteEnum.INITIATIVES);
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };
  const filterResult = (item: ITeam): boolean => {
    if (searchTerm === "") return true;

    return item.title.toLowerCase().includes(searchTerm.toLowerCase());
  };

  return (
    <AppBarLayout showMenu>
      <Layout
        top={
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="subtitle1">
                {t("initiatives.teams.selectTeam")}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                <BackButton
                  onClick={() => history.push(RouteEnum.INITIATIVES)}
                />
              </Typography>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.searchField}>
          <SearchField onSearch={handleSearch} />
        </div>
        <div className={classes.listWrapper}>
          <List className={classes.list}>
            <ListItem
              className={`${classes.listItem} ${
                !team ? classes.highlighted : ""
              }`}
              button
              divider
              onClick={() => selectTeam()}
            >
              {user?.squad?.title}
              <ArrowForwardIosIcon color="inherit" className={classes.icon} />
            </ListItem>
            {teams?.filter(filterResult).map((singleTeam) => (
              <ListItem
                key={singleTeam._id}
                className={`${classes.listItem} ${
                  team?._id === singleTeam._id ? classes.highlighted : ""
                }`}
                button
                divider
                onClick={() => selectTeam(singleTeam)}
              >
                {singleTeam.title}
                <ArrowForwardIosIcon color="inherit" className={classes.icon} />
              </ListItem>
            ))}
          </List>
        </div>
      </Layout>
    </AppBarLayout>
  );
});
