import React from "react";
import { ICommentListProps } from "pages/Initiatives/initiative";
import Card from "components/Card";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import CommentsListItem from "pages/Initiatives/Detail/CommentsListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const CommentsList: React.FC<ICommentListProps> = ({ comments }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <List className={classes.list}>
        {comments.map((comment) => (
          <CommentsListItem key={comment._id} comment={comment} />
        ))}
      </List>
    </Card>
  );
};

export default CommentsList;
